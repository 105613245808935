import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import debounce from 'lodash.debounce';

import axios from '../../axios';

import { getFilterOptions, sortNumbers, sortStrings } from '../../helpers/filter';

import { KEY_PRESS_CODE, SEARCH_DEBOUNCE_DELAY } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import { RULE_PRIORITY, RULE_PRIORITY_INDEX } from '../../constants/rule';

import { setSelectedFilters } from '../../redux/reducers/animal';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import Priority from '../../components/PriorityTag';
import RuleSnoozeIcon from '../../components/RuleSnoozeIcon.jsx';
import Filters from '../../components/filters/index.jsx';

export default function Rules(props) {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.rule);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [state, setState] = useState({
    actions: [],
    alert_modal: false,
    columnFilterOptions: {
      priorities: [],
      statuses: [],
      types: []
    },
    delete_modal: false,
    filters: {
      farms: props?.location?.filter?.farms || [],
      geofences: [],
      labels: [],
      sites: [],
      animals: []
    },
    isLoading: true,
    modal_message: '',
    modal_title: '',
    modal_type: '',
    query: '',
    rules: [],
    selectAll: false,
    selected_rules: [],
    tableData: []
  });

  useEffect(() => {
    getRules();
    initActions();
  }, []);

  useEffect(() => {
    initActions();
  }, [state.selected_rules, selectedFilters]);

  useEffect(() => {
    getRules();
  }, [state.filters]);

  function initActions() {
    setState(prevState => ({
      ...prevState,
      actions: [
        {
          label: (
            <Link className="app-link" to="/rules/builder/new">
              Add rule
            </Link>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!state.selected_rules?.length}
              style={{ padding: 0 }}
              onClick={() => toggleModal('delete_modal', 'Snooze')}>
              Snooze rule
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!state.selected_rules?.length}
              style={{ padding: 0 }}
              onClick={() => toggleModal('delete_modal', 'Unsnooze')}>
              Unsnooze rule
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!state.selected_rules?.length}
              style={{ padding: 0 }}
              onClick={() => archiveRule()}>
              Archive rule
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!Object.values(selectedFilters)?.length}
              style={{ padding: 0 }}
              onClick={() => clearFilters()}>
              Clear all filters
            </Button>
          )
        }
      ]
    }));
  }

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  const setFilters = (pagination, filters) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  const selectRecords = selected => {
    setState(prevState => ({
      ...prevState,
      selected_rules: selected,
      selectAll: prevState.rules?.length === selected.length
    }));
  };

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleKeyPress = charCode => {
    if (charCode === KEY_PRESS_CODE) {
      setState(prevState => ({ ...prevState, isLoading: true }));
      debounceSearch(getRules);
    }
  };

  async function getRules() {
    const response = await axios.get('rules', {
      params: {
        query: state.query,
        farm_ids: state.filters?.farms.map(x => x.value),
        label_ids: state.filters?.labels.map(x => x.value),
        geofence_ids: state.filters?.geofences.map(x => x.value),
        site_ids: state.filters?.sites.map(x => x.value),
        animal_ids: state.filters?.animals.map(x => x.value)
      }
    });

    if (response.status === 200) {
      // add flag for default rules //
      response.data.map(x => {
        x.isDefault = x.default_rule === 1;
        return x;
      });

      setState(prevState => ({
        ...prevState,
        isLoading: false,
        rules: response.data,
        tableData: response.data.slice(0, 25),
        columnFilterOptions: {
          ...prevState.columnFilterOptions,
          priorities: [...new Set(response.data?.map(item => item.data).map(d => d.action.value))].map(p => ({
            text: RULE_PRIORITY[p],
            value: p
          })),
          statuses: getFilterOptions(response?.data, 'snooze_status'),
          types: getFilterOptions(response.data, 'type_display_name')
        }
      }));
    }
  }

  async function sigleAction(id, type) {
    const modal_type = type === 'snoozable' ? 'Snooze' : 'Unsnooze';
    const selected_rules = [];

    selected_rules.push(id);
    setState(prevState => ({ ...prevState, selected_rules, modal_type: modal_type, selectAll: false }));
    toggleSingleModal('delete_modal', modal_type);
  }

  const toggleSingleModal = (modal, type) => {
    const title = type === 'Snooze' ? 'Snooze rule' : 'Unsnooze rule';
    const message =
      type === 'Snooze' ? 'Are you sure you want to snooze this rule?' : 'Are you sure you want to unsnooze this rule?';

    setState(prevState => ({
      ...prevState,
      [modal]: !prevState[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  };

  async function deleteRules() {
    let response = {};

    if (state.modal_type === 'Archive') {
      response = await axios.delete('rules/bulk', {
        data: { ids: state.selected_rules, force: true }
      });
    } else if (state.modal_type === 'Snooze') {
      response = await axios.put('rules/snooze/bulk', {
        ids: state.selected_rules,
        force: true,
        seconds: 86400
      });
    } else {
      response = await axios.put('rules/snooze/bulk', {
        ids: state.selected_rules,
        force: true,
        seconds: -1
      });
    }

    if (response.status && response.status === 200) {
      await getRules();
      setState(prevState => ({ ...prevState, delete_modal: false, selectAll: false, selected_rules: [] }));
    }
  }

  const archiveRule = () => {
    let isDefaultRuleExists = false;

    state.selected_rules?.map(x => {
      const rule = state.rules.filter(data => data.id === x);

      if (rule[0] && rule[0].isDefault) {
        isDefaultRuleExists = true;
      }
    });

    isDefaultRuleExists ? toggleModal('alert_modal', 'Alert') : toggleModal('delete_modal', 'Archive');
  };

  const toggleModal = (modal, type) => {
    const title =
      type === 'Archive'
        ? 'Archive rules'
        : type === 'Snooze'
        ? 'Snooze rules'
        : type === 'Alert'
        ? 'Alert'
        : 'Unsnooze rules';
    const message =
      type === 'Archive'
        ? 'Are you sure you want to archive selected rules? This action cannot be undone.'
        : type === 'Snooze'
        ? 'Are you sure you want to snooze selected rules?'
        : type === 'Alert'
        ? 'This is a default system Rule which can not be edited or archived.'
        : 'Are you sure you want to unsnooze selected rules?';

    setState(prevState => ({
      ...prevState,
      [modal]: !prevState[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  };

  function filtersUpdated(filters) {
    setState(prevState => ({ ...prevState, filters: { ...prevState.filters, ...filters } }));
  }

  const filtersClose = () => {
    setState(prevState => ({ ...prevState, filters_open: false }));
  };

  const commonColumnProperties = {
    ellipsis: true,
    sortDirections: ['ascend', 'descend']
  };
  const columns = [
    {
      ...commonColumnProperties,
      title: 'Rule ID',
      dataIndex: 'identifier',
      filteredValue: filteredInfo.identifier || null,
      fixed: 'left',
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier),
      render: (value, record) => (
        <Link to={`rule/${record.id}`}>
          {record.is_snoozed && <RuleSnoozeIcon></RuleSnoozeIcon>} {record.identifier}
        </Link>
      )
    },
    {
      ...commonColumnProperties,
      title: 'Rule Name',
      dataIndex: 'name',
      filteredValue: filteredInfo.name || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.name, b.name)
    },
    {
      ...commonColumnProperties,
      title: 'Rule Type',
      dataIndex: 'type_display_name',
      filteredValue: filteredInfo.type_display_name || null,
      filters: state.columnFilterOptions?.types,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.type_display_name : record?.type_display_name === value,
      sorter: (a, b) => sortStrings(a.type_display_name, b.type_display_name)
    },
    {
      ...commonColumnProperties,
      title: 'Rule Priority',
      dataIndex: 'rulepid',
      filteredValue: filteredInfo.rulepid || null,
      filters: state.columnFilterOptions?.priorities,
      width: COLUMN_SIZE.MD,
      render: (value, record) => {
        const priority = record.data.action ? RULE_PRIORITY[record.data.action.value] : '';
        return <Priority priority={priority}></Priority>;
      },
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.data.action.value : record?.data.action.value === value,
      sorter: (a, b) => {
        const strA = a.data.action ? RULE_PRIORITY_INDEX[a.data.action.value] : -1;
        const strB = b.data.action ? RULE_PRIORITY_INDEX[b.data.action.value] : -1;

        return sortNumbers(strA, strB);
      }
    },
    {
      ...commonColumnProperties,
      title: 'Rule Status',
      dataIndex: 'rulestatusid',
      filteredValue: filteredInfo.rulestatusid || null,
      filters: state.columnFilterOptions?.statuses,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.snooze_status : record?.snooze_status === value,
      render: (value, record) => (record.is_snoozed ? 'Snoozed' : 'Active'),
      sorter: (a, b) => {
        const strA = a.is_snoozed ? 'Snoozed' : 'Active';
        const strB = b.is_snoozed ? 'Snoozed' : 'Active';

        return sortStrings(strA, strB);
      }
    },

    {
      ...commonColumnProperties,
      title: 'Rule Triggers',
      dataIndex: 'triggers_count',
      filteredValue: filteredInfo.triggers_count || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => record.triggers_count === +value,
      sorter: (a, b) => sortNumbers(a.triggers_count, b.triggers_count)
    },
    {
      ...commonColumnProperties,
      title: 'Rule Objects',
      dataIndex: 'objects_count',
      filteredValue: filteredInfo.objects_count || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => record.objects_count === +value,
      sorter: (a, b) => sortNumbers(a.objects_count, b.objects_count)
    },
    {
      title: 'Snooze',
      dataIndex: 'actions',
      align: 'center',
      ellipsis: true,
      filteredValue: filteredInfo.rulesnoozzeid || null,
      fixed: 'right',
      width: COLUMN_SIZE.XS,
      accessor: d => ({ snooze: d.is_snoozed ? 'snoozed' : 'snoozable', id: d.id }),
      render: (value, record) => {
        const snooze = record.is_snoozed ? 'snoozed' : 'snoozable';
        return (
          <>
            <i
              style={{ cursor: 'pointer' }}
              title={record.is_snoozed ? 'Unsnooze' : 'Snooze'}
              onClick={() => sigleAction(record.id, snooze)}
              className={'rule_not-action mdi mdi-alarm ' + snooze}></i>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Filters
        farms={state.filters?.farms}
        open={state.filters_open}
        updateFilters={filtersUpdated}
        onClose={filtersClose}
        filter_types={['farm', 'geofence', 'label', 'site', 'animal']}
      />

      <div className="p-2 card">
        <AppTable
          headerClass="py-2"
          breadcrumb="List of Rules"
          searchPlaceholder="Search Rules"
          title="Rules"
          actions={state.actions}
          baseColumns={columns}
          dataSource={state.rules}
          filterable={false}
          loading={state.isLoading}
          searchDefaultValue={state.query}
          handleFilters={() => setState(prevState => ({ ...prevState, filters_open: !prevState.filters_open }))}
          handleOnChange={setFilters}
          handleOnSelectRecords={selectRecords}
          handleSearchChange={e => onChange(e.target.value, 'query')}
          handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
        />
      </div>

      <AppModal
        confirmButtonColor="danger"
        confirmButtonText={state.modal_type}
        isOpen={state.delete_modal}
        title={state.modal_title}
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={() => deleteRules()}>
        <div className="py-4">{state.modal_message}</div>
      </AppModal>

      <AppModal
        confirmButtonText="Close"
        cancelButtonHidden={true}
        isOpen={state.alert_modal}
        title={state.modal_title}
        handleCancel={() => toggleModal('alert_modal')}
        handleConfirm={() => toggleModal('alert_modal')}>
        <div className="py-4">{state.modal_message}</div>
      </AppModal>
    </>
  );
}
