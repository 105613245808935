import { memo, useEffect, useState } from 'react';
import { FeatureGroup, GeoJSON, LayersControl, Popup, useMap } from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { Link } from 'react-router-dom';

import L from 'leaflet';

import { capitalize } from '../../helpers/common';
import { getRandomColor } from '../../helpers/map';

import { GEOMETRY_TYPE } from '../../constants/map';

import AnimalDetails from '../../components/TrackLivestock/AnimalDetails';

import MapBaseLayers from './MapBaseLayers';
import MapTrackingFeature from './MapTrackingFeature';
import SiteFeature from './SiteFeature';
import { DEFAULT_LINE_COLOR, FULLSCREEN_CONTROL_POSITION, LAYERS_CONTROL_POSITION, ZOOM_MAX } from './constants';

const { Overlay } = LayersControl;

function MapTrackingLayers({ activeTab, animal, farm, farms, recordsWithGeoData, tracking, onAnimalClick }) {
  const map = useMap();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [state, setState] = useState({
    animal,
    bounds: [
      [-31.0, 13.0],
      [-31.0, 33.0]
    ],
    displayData: [],
    farm,
    features: { features: [] },
    features_master: {},
    features_sites: { features: [] },
    icon: '',
    position: [-29.132312, 26.057016],
    tracking
  });

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    tick();

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    tick();
  }, [farms, recordsWithGeoData, tracking]);

  function calculateBounds(features, reverseCoordinates = true) {
    if (!features || !features.length) return;

    const bounds = L.latLngBounds();

    features.forEach(feature => {
      if (feature.geometry && feature.geometry.coordinates) {
        const coordinates =
          feature.geometry.type === GEOMETRY_TYPE.POLYGON
            ? feature.geometry.coordinates.flat(1)
            : feature.geometry.type === GEOMETRY_TYPE.POINT
            ? [feature.geometry.coordinates]
            : feature.geometry.coordinates.flat(2);

        coordinates.forEach(coordinate => {
          if (Array.isArray(coordinate) && coordinate.length === 2) {
            bounds.extend(
              reverseCoordinates ? [coordinate.at(-1), coordinate.at(0)] : [coordinate.at(0), coordinate.at(-1)]
            );
          }
        });
      }
    });

    if (bounds.isValid()) {
      const center = bounds.getCenter();
      setState(prev => ({ ...prev, bounds }));

      if (map) {
        map.setView(center, Math.min(map.getBoundsZoom(bounds), ZOOM_MAX));
      }
    }
  }

  function addStartEndIcons(tracking) {
    if (!tracking?.features || !tracking.features.length) return tracking;

    const points = tracking.features.filter(x => x.geometry.type === GEOMETRY_TYPE.POINT);
    const startPoint = points?.at(0);
    const endPoint = points?.at(-1);

    return tracking?.features?.map(feature => {
      return feature.geometry?.type === GEOMETRY_TYPE.POINT
        ? feature === startPoint
          ? { ...feature, properties: { ...feature.properties, customIcon: 'start' } }
          : feature === endPoint
          ? { ...feature, properties: { ...feature.properties, customIcon: 'end' } }
          : feature
        : { ...feature, color: 'red' };
    });
  }

  async function tick() {
    const features = { type: 'FeatureGroup', features: [] };
    const geo = { type: 'FeatureCollection', features: [] };
    const sites = { type: 'Feature', features: [] };

    if (farm) {
      const fences = structuredClone(farm.geofences);
      const farmSites = structuredClone(farm.sites);

      fences.forEach(f => {
        const target = f.is_master ? geo.features : features.features;
        target.push({ ...f.geo_json, color: f.color });
      });

      farmSites.forEach(fs => {
        if (fs.geo_json.type) {
          sites.features.push({ ...fs.geo_json, color: fs.color });
        }
      });
    }

    if (farms?.length) {
      for (const farm of farms) {
        for (const farmGeofence of farm.geofences) {
          const target = farmGeofence.is_master ? geo.features : features.features;
          target.push({ ...farmGeofence.geo_json, color: farmGeofence.color });
        }
      }
    }

    if (recordsWithGeoData) {
      for (const gf of recordsWithGeoData) {
        const target =
          gf?.geo_json?.properties?.type === 'site' ? sites.features : gf?.is_master ? geo.features : features.features;

        if (gf.geo_json.geometry) {
          target.push({ ...gf?.geo_json, color: gf?.color });
        }
      }
    }

    let updatedTrackingFeaturesList = [];
    if (Array.isArray(tracking)) {
      for (const tr of tracking) {
        const validTrackingFeatures = tr?.features?.filter(t => t.geometry.coordinates.every(c => c !== null)) || [];

        updatedTrackingFeaturesList.push(addStartEndIcons({ ...tr, features: validTrackingFeatures }));
      }
    }

    const validTrackingFeatures = tracking?.features?.filter(t => t.geometry.coordinates.every(c => c !== null)) || [];

    const updatedTrackingFeatures = addStartEndIcons({ ...tracking, features: validTrackingFeatures });

    if (updatedTrackingFeaturesList?.length || updatedTrackingFeatures?.length) {
      calculateBounds(
        updatedTrackingFeaturesList?.length ? updatedTrackingFeaturesList.flat() : updatedTrackingFeatures,
        false
      );
    }

    if (sites?.features?.length) {
      calculateBounds(sites.features);
    }

    // IF NO TRACKING HISTORY FOUND - SHOW FARM //
    if (tracking?.features && !tracking.features?.length && geo.features?.length) {
      const f = [];

      for (const feat of geo.features) {
        const feature = structuredClone(feat);
        const coordinates = structuredClone(feature.geometry.coordinates?.at(0));
        const coordsReverted = [];

        for (const coordinate in coordinates) {
          const a = L.GeoJSON.coordsToLatLng(coordinates[coordinate]);
          coordsReverted[coordinate] = [a?.lat, a?.lng];
        }

        feature.geometry.coordinates[0] = coordsReverted;
        f.push(feature);
      }
    }

    if (recordsWithGeoData) {
      calculateBounds([...geo.features, ...features.features]);
    }

    setState(prev => ({
      ...prev,
      tracking: {
        ...tracking,
        features: updatedTrackingFeaturesList?.length ? updatedTrackingFeaturesList.flat() : updatedTrackingFeatures
      },
      features_master: geo,
      features_sites: sites,
      features
    }));
  }

  return (
    <>
      <LayersControl collapsed={!L.Browser.mobile} position={LAYERS_CONTROL_POSITION}>
        <MapBaseLayers />

        <Overlay checked name={capitalize(activeTab) || 'Livestock'}>
          <FeatureGroup edit={{ edit: false, delete: false }}>
            {state.features_sites?.features?.length !== 0 &&
              !state.new &&
              state.features_sites?.features?.map(i => (
                <SiteFeature
                  key={i?.properties?.id}
                  color={i?.color}
                  type={i?.geometry?.type}
                  geometry={i?.geometry?.coordinates}
                  properties={i?.properties}
                />
              ))}

            {state.tracking &&
              state.tracking.features?.length &&
              state.tracking?.features?.map((feature, index) => {
                if (feature?.properties?.isVisible || feature?.geometry?.type === GEOMETRY_TYPE.LINE) {
                  return (
                    <MapTrackingFeature
                      color={
                        feature?.geometry?.type === GEOMETRY_TYPE.LINE && index === 0
                          ? DEFAULT_LINE_COLOR
                          : getRandomColor()
                      }
                      geometry={feature.geometry?.coordinates}
                      key={feature.properties?.id}
                      properties={feature.properties}
                      type={feature.geometry?.type}
                      setAnimal={onAnimalClick}
                    />
                  );
                }
              })}

            {state?.features_master?.features?.map(item => (
              <GeoJSON
                data={item}
                key={item?.properties?.id}
                style={{
                  color: item.color,
                  weight: 3,
                  fillOpacity: 0.2,
                  opacity: 0.8
                }}>
                <Popup>
                  <p className="font-bold">{item.properties?.name}</p>
                  {item.properties?.description && <p>{item.properties?.description}</p>}
                  <Link to={`/geofence/${item.properties?.id}`}>More details</Link>
                </Popup>
              </GeoJSON>
            ))}
            {state?.features?.features?.map(item => (
              <GeoJSON
                data={item}
                key={item?.properties?.id}
                style={{
                  color: item.color,
                  weight: 2,
                  fillOpacity: 0.2,
                  opacity: 0.8
                }}>
                <Popup>
                  <p className="font-bold">{item.properties.name}</p>
                  {item.properties.description && <p>{item.properties.description}</p>}
                  <Link to={`/geofence/${item.properties.id}`}>More details</Link>
                </Popup>
              </GeoJSON>
            ))}
          </FeatureGroup>

          {!L.Browser.mobile && <FullscreenControl position={FULLSCREEN_CONTROL_POSITION} />}
          {animal && isFullScreen && <AnimalDetails animal={animal} />}
        </Overlay>
      </LayersControl>
    </>
  );
}

export default memo(MapTrackingLayers);
