import { Component } from 'react';
import { CompactPicker } from 'react-color';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Breadcrumb, BreadcrumbItem, Card, Col, Input, Row, Table } from 'reactstrap';

import axios from '../../axios';

import { NEW_FARM_KEY_MAP, blacklist_create } from '../../constants/farm';
import messages from '../../constants/messages';

import CancelSaveButtons from '../../components/CancelSaveButtons';
import { DEFAULT_SHAPE_COLOR, TYPE } from '../../components/Maps/constants';
import Map from '../../components/Maps/map';
import { errorToastHandler } from '../../components/action_notifier';

import countries from '../../countries.json';

function FarmRows(props) {
  if (props.farm === null) return null;

  let editing = props.editing;
  let farm = props.farm;
  let geoList = [];

  for (let geofence of props.geofences) {
    geoList.push({
      value: geofence.id,
      label: geofence.name,
      geofence: geofence
    });
  }

  let FARM_INFO = [];
  let index = 0;

  for (let key of Object.keys(farm)) {
    let value = farm[key];

    if (Array.isArray(value)) {
      if (editing) {
        if (key !== 'geofences') {
          value = value.map(e => e.name);
          value = value.join(', ');
        }
      } else {
        value = value.map(e => e.name);
        value = value.join(', ');
      }
    }

    if (editing) {
      let INPUT = (
        <Input
          type="text"
          placeholder={NEW_FARM_KEY_MAP[key]}
          name={key}
          defaultValue={value}
          onChange={e => {
            props.onChange(e.target.value, e.target.name);
          }}
        />
      );

      if (key === 'geofences') {
        INPUT = (
          <Select
            name="geofences"
            defaultValue={props.selected_geofences}
            onChange={props.multiselectChange}
            options={geoList}
            isMulti={true}
          />
        );
      }

      if (key === 'description') {
        INPUT = (
          <Input
            type="textarea"
            style={{ fieldSizing: 'content' }}
            placeholder={NEW_FARM_KEY_MAP[key]}
            name={key}
            defaultValue={value}
            onChange={e => {
              props.onChange(e.target.value, e.target.name);
            }}
          />
        );
      }

      if (key === 'herd_number') {
        INPUT = (
          <Input
            type="number"
            placeholder={NEW_FARM_KEY_MAP[key]}
            name={key}
            defaultValue={value}
            onChange={e => {
              props.onChange(e.target.value, e.target.name);
            }}
          />
        );
      }

      if (blacklist_create.includes(key)) {
        continue;
      }

      FARM_INFO.push(
        <tr key={index}>
          <td className="v-align-middle">
            <b>{NEW_FARM_KEY_MAP[key]}</b>
          </td>
          <td>{INPUT}</td>
        </tr>
      );
    } else {
      FARM_INFO.push(
        <tr>
          <td>
            <b>{NEW_FARM_KEY_MAP[key]}</b>
          </td>
          <td>{value}</td>
        </tr>
      );
    }

    index++;
  }

  return FARM_INFO;
}

class Farm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      selected_geofences: [],
      default_town: '',
      color: DEFAULT_SHAPE_COLOR,
      farm: {
        id: null,
        name: '',
        full_address: '',
        address: {},
        size: '',
        herd_nr: '',
        geofence_id: '',
        description: '',
        geofences: [],
        circumference: '',
        days_grazed: '',
        farm_rules: []
      },
      geofences: [],
      dropdownOpen: false,
      countries: [],
      selected_country: {}
    };
    this.onChange = this.onChange.bind(this);
    this.newFence = this.newFence.bind(this);
    this.onFarmEditChange = this.onFarmEditChange.bind(this);
    this.multiselectChange = this.multiselectChange.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.multiselectChangeFarm = this.multiselectChangeFarm.bind(this);
  }

  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onChange = (value, field) => {
    this.setState(state => ({ ...state, [field]: value }));
    this.forceUpdate();
  };

  onFarmEditChange = (value, field) => {
    this.setState(() => ({
      farm: {
        ...this.state.farm,
        [field]: value
      }
    }));
    this.forceUpdate();
  };

  onFarmEditChangeAddress = (value, field) => {
    this.setState(() => ({
      farm: {
        ...this.state.farm,
        address: {
          ...this.state.farm.address,
          [field]: value
        }
      }
    }));
  };

  multiselectChange = sle => {
    this.setState({
      selected_geofences: sle
    });
  };

  newFence(fence) {
    this.setState({
      ...this.state,
      farm: {
        ...this.state.farm,
        master_geofence: fence
      },
      has_fence: true
    });
  }

  multiselectChangeFarm(sle) {
    this.setState(state => ({
      ...state,
      selected_country: sle,
      farm: {
        ...state.farm,
        address: {
          ...state.farm.address,
          country: sle.value
        }
      }
    }));
  }

  componentDidMount() {
    //TODO: Get tag
    let farm = {
      name: '',
      address: '',
      full_address: '',
      description: '',
      herd_number: 0,
      geofences: []
    };
    let cntrs = [];
    for (let cnt of countries) {
      cntrs.push({ value: cnt, label: cnt });
    }
    this.setState(state => ({
      ...state,
      countries: cntrs,
      farm: farm,
      selected_geofences: farm.geofences.map(gf => {
        return {
          value: gf.id,
          label: gf.name,
          geofence: gf
        };
      }),
      has_fence: false
    }));
  }

  async saveFarm() {
    if (!this.state.has_fence) {
      errorToastHandler(messages.MASTER_GEOFENCE_REQUIRED);
      return;
    }
    const herdNumbers =
      this.state.farm.herd_number !== null && this.state.farm.herd_number !== ''
        ? [this.state.farm.herd_number.toString()]
        : [];
    await axios.post('farms', {
      name: this.state.farm.name,
      address: this.state.farm.address,
      description: this.state.farm.description,
      herd_numbers: herdNumbers,
      color: this.state.color,
      master_geofence: this.state.farm.master_geofence
    });

    this.props.history.push('/maps?tab=farm');
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">New Farm {this.state.farm.id}</h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/maps?tab=farm">List of Farms</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>New Farm</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <CancelSaveButtons
              handleCancel={() => this.props.history.push('/maps?tab=farm')}
              handleSave={this.saveFarm}
            />
          </Col>
        </Row>
        <Card>
          <Row>
            <Col xs="12" md="12" lg="12" className="global-table">
              <Row className="">
                <Col xs="12" md="12" lg="12">
                  <Table className="no-border mt-2 mb-2">
                    <tbody>
                      <tr>
                        <td colSpan="12">
                          <p className="text-muted mt-2 mb-2">Mark the size and borders of the farm.</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <label htmlFor="default_town" className="mb-0">
                            <b>Closest town or city</b>
                          </label>
                        </td>
                        <td>
                          <Input
                            id="default_town"
                            name="default_town"
                            placeholder="Closest town or city"
                            onChange={e => this.onChange(e.target.value, e.target.name)}></Input>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  {this.state.default_town && (
                    <Map
                      color={this.state.color}
                      defaultPosition={this.state.default_town}
                      editing={true}
                      farm={this.state.farm}
                      type={TYPE.FARM}
                      newGeofence={this.newFence}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        {this.state.default_town && (
          <Card>
            <Row>
              <Col xs="12" md="12" lg="12" className="global-table">
                <Table>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <h4>Farm Details</h4>
                      </td>
                    </tr>
                    <FarmRows
                      editing={true}
                      farm={this.state.farm}
                      geofences={this.state.geofences}
                      selected_geofences={this.state.selected_geofences}
                      multiselectChange={this.multiselectChange}
                      onChange={this.onFarmEditChange}
                    />
                    <tr>
                      <td className="v-align-middle">
                        <b>Farm Address</b>
                      </td>
                      <td>
                        <Input
                          type="text"
                          name="street"
                          onChange={e => this.onFarmEditChangeAddress(e.target.value, e.target.name)}
                          placeholder="Rose Street"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="v-align-middle">
                        <b>Geofence Color</b>
                      </td>
                      <td>
                        <CompactPicker
                          name="colour"
                          color={this.state.color}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              color: e.hex
                            });
                          }}></CompactPicker>
                      </td>
                    </tr>
                    <tr>
                      <td className="v-align-middle">
                        <b>Farm House Number / Name</b>
                      </td>
                      <td>
                        <Input
                          type="text"
                          name="house_number"
                          onChange={e => this.onFarmEditChangeAddress(e.target.value, e.target.name)}
                          placeholder="11a"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="v-align-middle">
                        <b>Farm City / Town</b>
                      </td>
                      <td>
                        <Input
                          type="text"
                          name="city"
                          onChange={e => this.onFarmEditChangeAddress(e.target.value, e.target.name)}
                          placeholder="Wellington"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="v-align-middle">
                        <b>Farm Postal Code</b>
                      </td>
                      <td>
                        <Input
                          type="text"
                          name="postal_code"
                          onChange={e => this.onFarmEditChangeAddress(e.target.value, e.target.name)}
                          placeholder="W31 142"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="v-align-middle">
                        <b>Farm Country</b>
                      </td>
                      <td>
                        <Select
                          name="country"
                          defaultValue={this.state.selected_country}
                          onChange={this.multiselectChangeFarm}
                          options={this.state.countries}
                          isMulti={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        )}
        <Row>
          <Col xs="12" md="12" lg="12">
            <CancelSaveButtons
              handleCancel={() => this.props.history.push('/maps?tab=farm')}
              handleSave={this.saveFarm}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Farm;
