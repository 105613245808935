import { useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import { Button, Typography } from 'antd';
import classnames from 'classnames';

import axios from '../../axios';

import { numberWithCommas } from '../../helpers/common';

import messages from '../../constants/messages';

import { fetchFarm } from '../../redux/actions/farm';
import { deleteGeofence, fetchGeofence, updateGeofence } from '../../redux/actions/geofence';
import { fetchLabels } from '../../redux/actions/label';

import Alerts from '../../components/Alerts';
import AppModal from '../../components/AppModal';
import CancelSaveButtons from '../../components/CancelSaveButtons';
import LoadingBar from '../../components/LoadingBar';
import { DEFAULT_SHAPE_COLOR, TYPE } from '../../components/Maps/constants';
import Map from '../../components/Maps/geo_map';
import ChooseGroupsModal from '../../components/Modals/ChooseGroupsModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import PrevNextButtons from '../../components/PrevNextButtons';
import { errorToastHandler, successToastHandler } from '../../components/action_notifier';

import AppDropdown from '../ui-components/dropdown';

const { Title } = Typography;

export default function Geofence({ history }) {
  const { id } = useParams();
  const geofenceId = +id;
  const dispatch = useDispatch();
  const { geofence, loading } = useSelector(state => state.geofence);
  const { data: labels } = useSelector(state => state.label);
  const { farm } = useSelector(state => state.farm);
  const { myPlan } = useSelector(state => state.subscription);
  const [state, setState] = useState({
    actions: [],
    activeTab: '1',
    chooseGroupModal: false,
    color: DEFAULT_SHAPE_COLOR,
    delete_modal: false,
    editing: false,
    farm: {},
    geofence: {
      id: null,
      farm_id: null,
      name: '',
      size: 0,
      animals_count: 0,
      description: '',
      circumference: 0,
      days_grazed: 0,
      position: [24.984627, 31.717946],
      geo_json: {}
    },
    geofence_edit: {
      id: null,
      farm_id: null,
      name: '',
      size: 0,
      animals_count: 0,
      description: '',
      circumference: 0,
      days_grazed: 0,
      position: [24.984627, 31.717946],
      geo_json: {}
    },
    groupOptions: [],
    isOpenConfirmationModal: false,
    map_key: 0,
    savedGroups: [],
    selectedGroups: [],
    selected_geofences: []
  });

  useEffect(() => {
    getGeofence();
    getLabels();
    initActions();
  }, []);

  useEffect(() => {
    getGeofence();
    getLabels();
  }, [geofenceId]);

  useEffect(() => {
    if (geofence) {
      getFarm();
      setState(prevState => ({
        ...prevState,
        color: geofence.color,
        geofence: {
          ...geofence
        },
        geofence_edit: {
          ...geofence
        },
        savedGroups: geofence?.labels?.length ? geofence?.labels?.map(l => ({ label: l?.name, value: l?.id })) : null
      }));
    }
  }, [geofence]);

  useEffect(() => {
    if (farm && geofence) {
      setState(prevState => ({
        ...prevState,
        color: geofence.color,
        geofence: {
          ...geofence,
          features: farm.geofences,
          sites: farm.sites
        },
        geofence_edit: {
          ...geofence,
          features: farm.geofences,
          sites: farm.sites
        },
        farm: structuredClone(farm),
        selectedGroups: farm?.labels?.length ? farm?.labels?.map(l => ({ label: l?.name, value: l?.id })) : null,
        savedGroups: geofence?.labels?.length ? geofence?.labels?.map(l => ({ label: l?.name, value: l?.id })) : null
      }));
    }
  }, [farm]);

  useEffect(() => {
    if (labels) {
      setState(prevState => ({
        ...prevState,
        groupOptions: labels
          .filter(label => label.farm?.id === state.geofence.farm_id)
          .map(x => ({ value: x.id, label: x.name, color: x.colour }))
      }));
    }
  }, [labels]);

  function initActions() {
    setState(prevState => ({
      ...prevState,
      actions: [
        {
          label: (
            <Button color="default" size="small" variant="link" style={{ padding: 0 }} onClick={() => editGeofence()}>
              Edit geofence
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!state.geofence.is_master}
              style={{ padding: 0 }}
              onClick={() => toggleModal('chooseGroupModal')}>
              Edit groups in geofence
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => toggleModal('delete_modal')}>
              Archive geofence
            </Button>
          )
        }
      ]
    }));
  }

  async function getFarm() {
    try {
      await dispatch(fetchFarm(geofence?.farm_id)).unwrap();
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  async function getLabels() {
    try {
      await dispatch(fetchLabels()).unwrap();
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  async function getGeofence() {
    try {
      await dispatch(fetchGeofence(geofenceId)).unwrap();
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    }
  }

  async function deleteCurrentGeofence() {
    try {
      await dispatch(deleteGeofence(geofenceId)).unwrap();
      successToastHandler(messages.ARCHIVED);
      history.push('/geofences');
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_DELETE_DATA);
    }
  }

  function editGeofence() {
    setState(prevState => ({ ...prevState, editing: true, map_key: state.map_key + 1 }));
    // forceUpdate();
  }

  function toggleModal(modal) {
    if (modal === 'chooseGroupModal') {
      setState(prevState => ({ ...prevState, selectedGroups: state.savedGroups }));
    }

    setState(prevState => ({ ...prevState, [modal]: !prevState[modal] }));
  }

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, geofence_edit: { ...prevState.geofence_edit, [field]: value } }));
    // forceUpdate();
  };

  function toggle(tab) {
    if (state.activeTab !== tab) {
      setState(prevState => ({ ...prevState, activeTab: tab }));
    }
  }

  const onSelectGroup = value => {
    setState(prevState => ({ ...prevState, selectedGroups: [...value] }));
  };

  const handleMapEdit = geoJSON => {
    setState(prevState => ({ ...prevState, geofence_edit: { ...prevState.geofence_edit, geo_json: geoJSON } }));
  };

  async function updateCurrentGeofence() {
    // EVENT TRIGGER FOR MAP 'SAVE' ACTION //
    // if (document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top')) {
    //   document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top li a').click();
    // }
    //
    // // WAIT FOR 200 MILISECONDS TO GET DATA //
    // await setTimeout(() => {}, 200);

    try {
      if (!geofenceId) return;
      await dispatch(updateGeofence({ id: geofenceId, data: { ...state.geofence_edit, color: state.color } })).unwrap();
      setState(prevState => ({ ...prevState, editing: false }));
      await getGeofence();
      //history.push(geofenceId);
      successToastHandler(messages.UPDATED);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_UPDATE_DATA);
    }
  }

  async function onSaveGroups() {
    const groupIds = state.selectedGroups.map(g => g.value);

    if (groupIds?.length) {
      try {
        await axios.put('geofences/updateGroupGeofence/' + geofenceId, { group_ids: groupIds });
        getGeofence();
      } catch (error) {
        // console.error(error);
      }
    }

    toggleModal('chooseGroupModal');
  }

  if (!state.geofence.id) return null;

  return (
    <>
      {loading ? (
        <LoadingBar />
      ) : (
        <>
          <Row>
            <Col xs="6" md="6" lg="6">
              <Title level={4}>
                {state.editing && 'Edit '}Geofence - {state.geofence.name}
              </Title>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/maps?tab=geofence">List of Geofences</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  {state.editing && 'Edit '}Geofence {state.geofence.name}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs="6" md="6" lg="6">
              <PrevNextButtons
                nextId={state.geofence.next_id}
                prevId={state.geofence.prev_id}
                handlePrev={() => history.push(`/geofence/${state.geofence.prev_id}`)}
                handleNext={() => history.push(`/geofence/${state.geofence.next_id}`)}
              />
              {!state.editing && (
                <div className="flex-reverse">
                  <AppDropdown label="Actions" items={state.actions} />
                </div>
              )}
              {state.editing && (
                <>
                  <CancelSaveButtons
                    handleCancel={() =>
                      setState(prevState => ({
                        ...prevState,
                        editing: false,
                        map_key: state.map_key + 1
                      }))
                    }
                    handleSave={updateCurrentGeofence}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12" lg="12">
              <Card>
                <Row>
                  <Col
                    xs="12"
                    md="12"
                    lg="12"
                    className={`
								${state.editing ? '' : 'rg-no-space'}
							`}>
                    <Map
                      className={state.editing ? 'editing' : ''}
                      type={TYPE.GEOFENCE}
                      geofence={state.editing ? state.geofence_edit : state.geofence}
                      color={state.color}
                      editing={state.editing}
                      onEdit={handleMapEdit}
                    />
                  </Col>
                </Row>
              </Card>
              {state.editing && (
                <Card>
                  <Row>
                    <Col xs="12" md="12" lg="12" className="global-table">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <Title level={4}>Geofence {state.geofence.name} Details</Title>
                            </td>
                          </tr>
                          <tr>
                            <td className="v-align-middle">
                              <b>Geofence Name</b>
                            </td>
                            <td>
                              {!state.geofence.is_master && (
                                <Input
                                  name="name"
                                  defaultValue={state.geofence_edit.name}
                                  onChange={e => onChange(e.target.value, e.target.name)}
                                />
                              )}
                              {state.geofence.is_master && state.geofence.name}
                            </td>
                          </tr>
                          <tr>
                            <td className="v-align-middle">
                              <b>Geofence Color</b>
                            </td>
                            <td>
                              <CompactPicker
                                name="colour"
                                color={state.color}
                                onChange={e => setState(prevState => ({ ...prevState, color: e.hex }))}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="v-align-middle">
                              <b>Farm ID</b>
                            </td>
                            <td>{state.geofence.farm_id}</td>
                          </tr>
                          <tr>
                            <td className="v-align-middle">
                              <b>Size (ha)</b>
                            </td>
                            <td>{state.geofence.size}</td>
                          </tr>
                          <tr>
                            <td className="v-align-middle">
                              <b>Perimeter (km)</b>
                            </td>
                            <td>{state.geofence.circumference}</td>
                          </tr>
                          <tr>
                            <td className="v-align-middle">
                              <b>Description</b>
                            </td>
                            <td>
                              <Input
                                type="textarea"
                                name="description"
                                defaultValue={state.geofence_edit.description}
                                onChange={e => onChange(e.target.value, e.target.name)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
            {!state.editing && (
              <Col xs="12" md="12" lg="12">
                <Card>
                  <TabContent activeTab={state.activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <Row>
                            <Col xs="12" md="12" lg="12" className="global-table">
                              <Table responsive className="width">
                                <tbody>
                                  <tr>
                                    <td colSpan="2">
                                      <Title level={4}>Geofence {state.geofence.name} Details</Title>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Geofence ID</b>
                                    </td>
                                    <td>{state.geofence.identifier}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Geofence Name</b>
                                    </td>
                                    <td>{state.geofence?.name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Geofence Size (ha)</b>
                                    </td>
                                    <td>{numberWithCommas(Number(state.geofence?.size).toFixed(2))}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Geofence Perimeter (km)</b>
                                    </td>
                                    <td>{numberWithCommas(Number(state.geofence?.circumference).toFixed(2))}</td>
                                  </tr>
                                  {['trial', 'advanced', 'basic'].includes(myPlan.type) ? null : (
                                    <>
                                      <tr>
                                        <td>
                                          <b>Assigned Livestock</b>
                                        </td>
                                        <td>{numberWithCommas(state.geofence?.active_livestock_count)}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <b>Expected Tracked Livestock</b>
                                        </td>
                                        <td>{numberWithCommas(state.geofence?.livestock_with_tag)}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <b>Actual Tracked Livestock</b>
                                        </td>
                                        <td>{numberWithCommas(state.geofence?.actual_livestock)}</td>
                                      </tr>
                                    </>
                                  )}

                                  <tr>
                                    <td>
                                      <b>Assigned Groups</b>
                                    </td>
                                    <td>
                                      {this?.state?.geofence?.labels?.map((l, i) => (
                                        <span key={l.id}>
                                          {i !== 0 ? <span>{', '}</span> : null}
                                          <Link to={`/group/${l.id}`}>{l.name}</Link>
                                        </span>
                                      ))}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Geofence Description</b>
                                    </td>
                                    <td>{state.geofence_edit.description} </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Card>
              </Col>
            )}
          </Row>
          {state.editing && (
            <Row className="mb-4">
              <Col>
                <CancelSaveButtons
                  handleCancel={() =>
                    setState(prevState => ({
                      ...prevState,
                      editing: false,
                      map_key: state.map_key + 1
                    }))
                  }
                  handleSave={updateCurrentGeofence}
                />
              </Col>
            </Row>
          )}
          <Card>
            <Row className="pad-10 pb-0">
              <Col sm="12" className="mb-0">
                <h5>History</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Nav tabs className="fancy-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === '1'
                      })}
                      onClick={() => {
                        toggle('1');
                      }}>
                      Notifications
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="12">
                <TabContent className="no-bg" activeTab={state.activeTab}>
                  <TabPane tabId="1">
                    <Alerts type="full" query={{ geofence_ids: [geofenceId] }} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>

          <ChooseGroupsModal
            isOpen={state.chooseGroupModal}
            options={state.groupOptions}
            values={state.selectedGroups}
            handleGroupsChange={onSelectGroup}
            onCancel={() => toggleModal('chooseGroupModal')}
            onSave={() => setState(prevState => ({ ...prevState, isOpenConfirmationModal: true }))}
          />

          <AppModal
            isOpen={state.delete_modal}
            confirmButtonColor="danger"
            confirmButtonText="Archive"
            title="Archive geofence"
            handleCancel={() => toggleModal('delete_modal')}
            handleConfirm={() => deleteCurrentGeofence()}>
            <div className="py-4">Are you sure you want to archive this geofence? This action cannot be undone.</div>
          </AppModal>

          <ConfirmationModal
            isOpen={state.isOpenConfirmationModal}
            description={
              'One or more groups are already assigned to a different geofence. Do you want to update the geofence for these groups?'
            }
            onCancel={() => setState(prevState => ({ ...prevState, isOpenConfirmationModal: false }))}
            onSubmit={() => {
              onSaveGroups();
              setState(prevState => ({ ...prevState, isOpenConfirmationModal: false }));
            }}
            submitText={'Yes'}
            submitButtonType={'primary'}
          />
        </>
      )}
    </>
  );
}
