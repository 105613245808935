import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, GeoJSON, LayerGroup, LayersControl, MapContainer, Popup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { Link } from 'react-router-dom';

import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import { reverseCoordinates } from '../../helpers/map';

import Feature from './Feature';
import MapBaseLayers from './MapBaseLayers';
import SiteFeature from './SiteFeature';
import {
  DEFAULT_SHAPE_COLOR,
  EDIT_CONTROL_POSITION,
  FULLSCREEN_CONTROL_POSITION,
  LAYERS_CONTROL_POSITION,
  TYPE,
  ZOOM
} from './constants';
import './styles.scss';

const provider = new OpenStreetMapProvider();
const { Overlay } = LayersControl;

function MapSimple(props) {
  const featureGroupRef = useRef(null);
  const mapRef = useRef(null);
  const [state, setState] = useState({
    bounds: null,
    color: props.color || DEFAULT_SHAPE_COLOR,
    draw: {},
    enableDraw: true,
    farm: props.farm,
    masterGeofences: [],
    geofences: [],
    position: [],
    set: false,
    sites: { features: [] }
  });

  useEffect(() => {
    tick();
  }, []);

  useEffect(() => {
    if (props.farm) {
      tick();
    }
  }, [props.farm]);

  useEffect(() => {
    if (state.masterGeofences?.features?.length) {
      calculateMapCenter();
      calculateMapBounds();
    }
  }, [state.masterGeofences]);

  useEffect(() => {
    calculateMapCenter();
  }, [props.defaultPosition]);

  useEffect(() => {
    if (mapRef?.current) {
      const mapContainer = mapRef?.current?.getContainer();

      props.editing ? mapContainer.classList.add('editing') : mapContainer.classList.remove('editing');
    }
  }, [props.editing]);

  useEffect(() => {
    if (!state.leaflet_id) return;

    mapRef?.current?.eachLayer(layer => {
      if (layer._leaflet_id === state.leaflet_id && typeof layer.setStyle === 'function') {
        layer.setStyle({ color: props.color });
      }
    });
  }, [props.color]);

  function created(e) {
    console.log('CREATE', e);
    setState(prevState => ({ ...prevState, enableDraw: false }));

    const layers = L.layerGroup().addLayer(e.layer);

    setState(prevState => ({ ...prevState, leaflet_id: e.layer._leaflet_id }));

    layers.eachLayer(a => {
      if (props.type === TYPE.FARM) {
        if (props.onCreateGeofence) {
          props.onCreateGeofence(a.toGeoJSON());
        }
      }
    });
  }

  function edited(e) {
    setState(prevState => ({ ...prevState, enableDraw: false }));

    e.layers.eachLayer(a => {
      if (props.type === TYPE.FARM) {
        if (props.editFence) {
          props.editFence(a.toGeoJSON());
        }
      }
    });
  }

  function remove() {
    setState(prevState => ({ ...prevState, enableDraw: true }));
  }

  async function calculateMapCenter() {
    const set_position = props.defaultPosition ? props.defaultPosition : props.farm?.address?.full_address;

    if (!set_position) return;

    try {
      const address = await provider.search({ query: set_position });

      if (!address) return;

      const position = address.length ? [+address?.at(0)?.y, +address?.at(0)?.x] : state.position;

      if (position?.length && position.every(p => !Number.isNaN(p))) {
        setState(prevState => ({ ...prevState, position }));

        if (mapRef?.current) {
          mapRef?.current?.setView(position, mapRef?.current?.getZoom());
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function calculateMapBounds() {
    let bounds;

    if (state.masterGeofences?.features?.length) {
      const group = new L.geoJson(state.masterGeofences);
      bounds = group.getBounds();

      if (bounds.isValid()) {
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();

        const corner1 = L.latLng(parseFloat(northEast.lng), parseFloat(northEast.lat));
        const corner2 = L.latLng(parseFloat(southWest.lng), parseFloat(southWest.lat));

        bounds = L.latLngBounds(corner1, corner2);

        setState(prevState => ({ ...prevState, farm: props.farm, bounds }));

        if (mapRef?.current) {
          mapRef?.current?.fitBounds(bounds, { padding: [10, 10] });
        }
      }
    }
  }

  function tick() {
    const geofences = { type: 'FeatureGroup', features: [] };
    const masterGeofences = { type: 'FeatureCollection', features: [] };
    const sites = { type: 'Feature', features: [] };

    const farmGeofencesClone = structuredClone(props?.farm?.geofences);
    const farmSitesClone = structuredClone(props?.farm?.sites || []);

    for (const farmSiteClone of farmSitesClone) {
      if (farmSiteClone.geo_json.type) {
        sites.features.push({ ...farmSiteClone.geo_json, color: farmSiteClone.color });
      }
    }

    for (const farmGeofenceClone of farmGeofencesClone) {
      if (farmGeofenceClone.is_master) {
        const coordinates = structuredClone(farmGeofenceClone?.geo_json?.geometry?.coordinates?.at(0));
        farmGeofenceClone.geo_json.geometry.coordinates[0] = reverseCoordinates(coordinates);
        masterGeofences.features.push(farmGeofenceClone.geo_json);
      } else {
        geofences.features.push({ ...farmGeofenceClone.geo_json, color: farmGeofenceClone.color });
      }
    }

    setState(prevState => ({ ...prevState, masterGeofences, geofences, sites, set: true }));
  }

  return (
    <>
      <MapContainer
        className="farms map"
        attributionControl={false}
        center={[-29.132312, 26.057016]}
        bounds={state.bounds || null}
        ref={mapRef}
        zoom={ZOOM}>
        <LayersControl collapsed={!L.Browser.mobile} position={LAYERS_CONTROL_POSITION}>
          <MapBaseLayers />

          <Overlay checked name="Farm">
            <LayerGroup>
              <FeatureGroup ref={featureGroupRef}>
                <EditControl
                  draw={{
                    polygon: props.editing &&
                      state.enableDraw && {
                        shapeOptions: {
                          color: state.color,
                          fillOpacity: 0.5,
                          opacity: 0.5
                        }
                      },
                    circle: false,
                    circlemarker: false,
                    marker: false,
                    point: false,
                    polyline: false,
                    rectangle: false
                  }}
                  edit={{ edit: true, remove: true }}
                  position={EDIT_CONTROL_POSITION}
                  onEdited={edited}
                  onCreated={created}
                  onDeleted={remove}
                />

                {state.set &&
                  state.masterGeofences?.features?.map(i => {
                    return (
                      <Feature
                        color={state.color}
                        geometry={i.geometry.coordinates}
                        id={i.properties?.id}
                        key={i.properties?.id}
                        type={i.geometry.type}
                        setAnimal={props.setAnimal}>
                        {i?.properties && (
                          <Popup>
                            <p className="font-bold">{i?.properties?.name}</p>
                            {i?.properties?.description && <p>{i?.properties?.description}</p>}
                            <Link to={`/geofence/${i?.properties?.id}`}>More details</Link>
                          </Popup>
                        )}
                      </Feature>
                    );
                  })}
              </FeatureGroup>

              {state.sites.features.length &&
                !state.new &&
                state.sites.features.map(i => (
                  <SiteFeature
                    key={i?.properties?.id}
                    type={i.geometry.type}
                    color={i.color}
                    geometry={i.geometry.coordinates}
                    properties={i.properties}
                  />
                ))}

              {state.geofences?.features?.map(item => (
                <GeoJSON
                  key={item?.properties?.id}
                  data={item}
                  style={{
                    color: item.color,
                    weight: 2,
                    fillOpacity: 0.2,
                    opacity: 0.8,
                    className: 'outline'
                  }}>
                  <Popup>
                    <p className="font-bold">{item.properties?.name}</p>
                    {item.properties?.description && <p>{item.properties?.description}</p>}
                    <Link to={`/geofence/${item.properties?.id}`}>More details</Link>
                  </Popup>
                </GeoJSON>
              ))}
            </LayerGroup>
            {!L.Browser.mobile && <FullscreenControl position={FULLSCREEN_CONTROL_POSITION} />}
          </Overlay>
        </LayersControl>
      </MapContainer>
    </>
  );
}

export default MapSimple;
