import { createSlice } from '@reduxjs/toolkit';

import { SLIDER_DEFAULT_TIME_FRAME, SLIDER_DEFAULT_TIME_INTERVAL } from '../../components/TrackLivestock/constants';

import { fetchAnimal, fetchAnimalTags, fetchAnimals, fetchAnimalsTags } from '../actions/animal';

const animalSlice = createSlice({
  name: 'animal',
  initialState: {
    animalTags: {},
    calfAry: [],
    data: null,
    error: null,
    loading: false,
    mapData: {},
    selectedFilters: {},
    selectedIds: [],
    sireAry: [],
    tags: {},
    timeSlidersVisible: false,
    time_frame: SLIDER_DEFAULT_TIME_FRAME,
    time_interval: SLIDER_DEFAULT_TIME_INTERVAL
  },
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setMapData: (state, action) => {
      state.mapData = action.payload;
    },
    setTimeSlidersVisible: state => {
      state.timeSlidersVisible = !state.timeSlidersVisible;
    },
    setSliderData: (state, action) => {
      state[action.payload.field] = action.payload.value;
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Fetch Animals
      .addCase(fetchAnimals.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnimals.fulfilled, (state, action) => {
        state.loading = false;
        state.calfAry = action.payload.calfAry;
        state.sireAry = action.payload.sireAry;
        state.data = action.payload.data;
      })
      .addCase(fetchAnimals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Animal
      .addCase(fetchAnimal.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnimal.fulfilled, (state, action) => {
        state.loading = false;
        state.animal = action.payload;
      })
      .addCase(fetchAnimal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Animals Tags
      .addCase(fetchAnimalsTags.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnimalsTags.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = action.payload;
      })
      .addCase(fetchAnimalsTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Animal Tags
      .addCase(fetchAnimalTags.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnimalTags.fulfilled, (state, action) => {
        state.loading = false;
        state.animalTags = action.payload;
      })
      .addCase(fetchAnimalTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setMapData, setSelectedFilters, setSelectedIds, setSliderData, setTimeSlidersVisible } =
  animalSlice.actions;
export default animalSlice.reducer;
