import { createSlice } from '@reduxjs/toolkit';

const mapSlice = createSlice({
  name: 'map',
  initialState: {
    isFullScreen: false,
    data: {}
  },
  reducers: {
    setMapMode: (state, action) => {
      state.isFullScreen = action.payload;
    },
    setMapData: (state, action) => {
      state.data = { ...action.payload };
    }
  },
  extraReducers: () => {}
});

export const { setMapMode, setMapData } = mapSlice.actions;
export default mapSlice.reducer;
