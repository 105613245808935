export const getColorName = color => {
  switch (color) {
    case 'piss yellow':
      return 'khaki yellow';
    case 'bile':
      return 'avocado';
    default:
      return color;
  }
};

export const downloadCSV = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const capitalize = string => {
  return string ? string?.charAt(0)?.toUpperCase() + string?.slice(1) : '';
};

export const hexToRGB = (hex, alpha) => {
  if (!hex) return null;

  const r = parseInt(hex?.slice(1, 3), 16);
  const g = parseInt(hex?.slice(3, 5), 16);
  const b = parseInt(hex?.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  }

  return 'rgb(' + r + ', ' + g + ', ' + b + ')';
};

export const formatArrayToOptions = (array, label, value) => {
  return array?.map(x => {
    return { value: x[value], label: x[label] };
  });
};

export const formatArrayToFilterOptions = (array, label, value) => {
  return array?.map(x => {
    return { value: x[value], text: x[label] };
  });
};

export const getElementsByField = (array, field, value) => {
  return array?.filter(x => x[field] === value);
};

export const mpsToKmph = mps => {
  return 3.6 * mps;
};

export function numberWithCommas(x) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
