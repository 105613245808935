import React, { Suspense, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { Tabs, Typography } from 'antd';

import LoadingBar from '../../components/LoadingBar';

const { Title, Text } = Typography;

const GatewaysTable = lazy(() => import('../../components/Tables/GatewaysTable'));
const TagsTable = lazy(() => import('../../components/Tables/TagsTable'));

const DEFAULT_TAB = 'tags';

export default function Devices() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');
  const items = [
    {
      key: 'tags',
      label: 'Tags',
      children: (
        <Suspense fallback={<LoadingBar />}>
          <TagsTable />
        </Suspense>
      )
    },
    {
      key: 'gateways',
      label: 'Gateways',
      children: (
        <Suspense fallback={<LoadingBar />}>
          <GatewaysTable />
        </Suspense>
      )
    }
  ];

  return (
    <div className="card p-2">
      <Title level={4}>Kraal Devices</Title>
      <Breadcrumb>
        <BreadcrumbItem>
          <Text type="secondary">List of Kraal Tags and Gateways</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <Tabs defaultActiveKey={tab || DEFAULT_TAB} items={items} />
    </div>
  );
}
