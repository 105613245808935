import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, GeoJSON, LayerGroup, LayersControl, MapContainer, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { Link } from 'react-router-dom';

import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import { reverseCoordinates } from '../../helpers/map';

import Feature from './Feature';
import MapBaseLayers from './MapBaseLayers';
import SiteFeature from './SiteFeature';
import { EDIT_CONTROL_POSITION, FULLSCREEN_CONTROL_POSITION, LAYERS_CONTROL_POSITION, TYPE, ZOOM } from './constants';
import './styles.scss';

const { Overlay } = LayersControl;
const provider = new OpenStreetMapProvider();

export default function MapFence(props) {
  const mapRef = useRef(null);
  const featureGroupRef = useRef(null);
  const [state, setState] = useState({
    bounds: [
      [-31.0, 13.0],
      [-31.0, 33.0]
    ],
    color: props.color,
    editing: props.editing,
    enableDraw: props.editing,
    features: { features: [] },
    features_master: {},
    features_sites: { features: [] },
    geo_json: {},
    geofence: structuredClone(props.geofence),
    leaflet_id: null,
    new: props.new || false,
    position: [-29.132312, 26.057016]
  });

  useEffect(() => {
    tick();
  }, []);

  useEffect(() => {
    if (mapRef?.current) {
      const mapContainer = mapRef?.current?.getContainer();

      props.editing ? mapContainer.classList.add('editing') : mapContainer.classList.remove('editing');
    }
  }, [props.editing]);

  useEffect(() => {
    if (!state.leaflet_id) return;

    mapRef.current.leafletElement.eachLayer(layer => {
      if (layer._leaflet_id === state.leaflet_id && typeof layer.setStyle === 'function') {
        layer.setStyle({ color: props.color });
      }
    });
  }, [props.color]);

  useEffect(() => {
    calculateMapCenter();
    calculateMapBounds();
  }, [state.features_master]);

  useEffect(() => {
    if (props.geofence) {
      tick();
      calculateMapCenter();
      calculateMapBounds();
    }
  }, [props.geofence]);

  async function calculateMapCenter() {
    const geofence = state.geofence || props.geofence;

    if (!geofence) return;

    let position;

    if (geofence.position) {
      position = geofence.position;
    }

    try {
      if (!geofence.address) return;

      const address = await provider.search({ query: geofence.address });

      if (!address) return;

      position = address.length ? [+address?.at(0)?.y, +address?.at(0)?.x] : state.position;

      if (position && position.every(p => !Number.isNaN(p))) {
        setState(prevState => ({ ...prevState, position }));

        if (mapRef?.current) {
          mapRef?.current?.setView(position, mapRef?.current?.getZoom());
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function calculateMapBounds() {
    let bounds;
    const geoJSON = state.geofence?.is_master ? state.features_master : state.features;

    if (geoJSON?.features?.length) {
      const group = new L.geoJson(geoJSON);
      bounds = group.getBounds();

      if (bounds.isValid()) {
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();

        const corner1 = L.latLng(parseFloat(northEast.lat), parseFloat(northEast.lng));
        const corner2 = L.latLng(parseFloat(southWest.lat), parseFloat(southWest.lng));

        bounds = L.latLngBounds(corner1, corner2);

        setState(prevState => ({ ...prevState, bounds }));

        if (mapRef?.current) {
          mapRef?.current?.fitBounds(bounds, { padding: [10, 10] });
        }
      }
    }
  }

  async function tick() {
    if (!props.geofence && !props.geofence?.farm_id) return;

    const geofence = state.new ? null : structuredClone(props.geofence);

    if (!state.new) {
      const coordinates = structuredClone(geofence.geo_json.geometry.coordinates?.at(0));

      geofence.geo_json.geometry.coordinates[0] = reverseCoordinates(coordinates);
    }

    const features = { type: 'FeatureGroup', features: [] };
    const masterGeofences = { type: 'FeatureCollection', features: [] };
    const sites = { type: 'Feature', features: [] };

    const geofencesClone = structuredClone(props.geofence?.features);
    const sitesClone = structuredClone(props.geofence?.sites);

    geofencesClone?.forEach(f => {
      const target = f.is_master ? masterGeofences.features : features.features;
      target.push({ ...f.geo_json, color: f.color });
    });

    sitesClone?.forEach(s => {
      sites.features.push({ ...s.geo_json, color: s.color });
    });

    setState(prevState => ({
      ...prevState,
      features,
      features_master: masterGeofences,
      features_sites: sites,
      geofence: geofence ?? props.geofence
    }));
  }

  function created(e) {
    setState(prevState => ({ ...prevState, enableDraw: false }));

    const layers = L.layerGroup().addLayer(e.layer);

    setState(prevState => ({ ...prevState, leaflet_id: e.layer._leaflet_id }));

    layers.eachLayer(l => {
      if (props.type === TYPE.GEOFENCE) {
        props.newGeofence(l.toGeoJSON());
      }
    });
  }

  function edited(e) {
    setState(prevState => ({ ...prevState, enableDraw: false }));

    e.layers.eachLayer(a => {
      if (props.type === TYPE.GEOFENCE) {
        if (props.onEdit) {
          // props.editFence(a.toGeoJSON());
          props.onEdit(a.toGeoJSON());
        }
      }
    });
  }

  function onDelete() {
    setState(prevState => ({ ...prevState, enableDraw: true }));
  }

  // ROMMY - RESETING VERTEX TO FIX THE MAP SWITCH VIEW //
  function handleEditVertex(e) {
    if (e.layers && props.type === TYPE.GEOFENCE) {
      let geo_json = structuredClone(props.geofence.geo_json);
      geo_json.geometry.coordinates[0] = [];
      e.layers.eachLayer(a => {
        if (props.onEdit && a._index != undefined) {
          let b = a.toGeoJSON();
          geo_json.geometry.coordinates[0][a._index] = b.geometry.coordinates;
        }
      });
      if (geo_json.geometry.coordinates[0].length) {
        geo_json.geometry.coordinates[0].push(geo_json.geometry.coordinates[0][0]);
      }
      props.onEdit(geo_json);
    }
  }

  // function handleEditVertex(layerGroup) {
  //   if (!layerGroup.layers || props.type !== TYPE.GEOFENCE) return;
  //
  //   const geoJson = structuredClone(props.geofence?.geo_json);
  //
  //   if (!geoJson?.geometry?.coordinates?.[0]) return;
  //
  //   const updatedCoordinates = [];
  //
  //   layerGroup.layers.eachLayer(layer => {
  //     if (props.onEdit && layer._index !== undefined) {
  //       const geoJsonLayer = layer.toGeoJSON();
  //       updatedCoordinates[layer._index] = geoJsonLayer.geometry.coordinates;
  //     }
  //   });
  //
  //   if (updatedCoordinates.length) {
  //     updatedCoordinates.push(updatedCoordinates[0]);
  //     geoJson.geometry.coordinates[0] = updatedCoordinates;
  //
  //     props.onEdit(geoJson);
  //   }
  // }
  return (
    <MapContainer
      //key={JSON.stringify(props.geofence)}
      attributionControl={false}
      ref={mapRef}
      className="farms map"
      center={state.position}
      bounds={state.bounds || null}
      zoom={ZOOM}>
      <LayersControl collapsed={!L.Browser.mobile} position={LAYERS_CONTROL_POSITION}>
        <MapBaseLayers />

        <Overlay checked name="Geofence">
          <LayerGroup>
            <FeatureGroup ref={featureGroupRef}>
              <EditControl
                draw={{
                  polygon: props.editing &&
                    state.enableDraw && {
                      shapeOptions: {
                        color: state.color,
                        fillOpacity: 0.5,
                        opacity: 0.5
                      }
                    },
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  point: false,
                  polyline: false,
                  rectangle: false
                }}
                edit={{ featureGroup: featureGroupRef?.current, edit: true, remove: true }}
                position={EDIT_CONTROL_POSITION}
                onEdited={edited}
                onCreated={created}
                onDeleted={onDelete}
                // onEditVertex={handleEditVertex}
              />

              {state.geofence.geo_json.type != null && !state.new && (
                <Feature
                  color={state.color}
                  geometry={state.geofence.geo_json.geometry.coordinates}
                  id={state.geofence?.id}
                  type={state.geofence.geo_json.geometry.type}
                />
              )}
            </FeatureGroup>

            {state.features_sites.features.length &&
              !state.new &&
              state.features_sites.features
                .filter(i => i.geometry != null && i.geometry.type != null)
                .map(i => (
                  <SiteFeature
                    key={JSON.stringify(i)}
                    color={i.color}
                    type={i.geometry.type}
                    geometry={i.geometry.coordinates}
                    properties={i.properties}
                  />
                ))}

            {state?.features_master?.features?.map(item => (
              <GeoJSON
                key={item?.properties?.id}
                data={item}
                style={{
                  color: item.color,
                  weight: 3,
                  fillOpacity: 0.2,
                  opacity: 0.8
                }}>
                <Popup>
                  <p className="font-bold">{item.properties.name}</p>
                  {item.properties.description && <p>{item.properties.description}</p>}
                  <Link to={`/geofence/${item.properties.id}`}>More details</Link>
                </Popup>
              </GeoJSON>
            ))}

            {state?.features?.features?.map(item => (
              <GeoJSON
                key={item?.properties?.id}
                data={item}
                style={{
                  color: item.color,
                  weight: 2,
                  fillOpacity: 0.2,
                  opacity: 0.8
                }}>
                <Popup>
                  <p className="font-bold">{item.properties.name}</p>
                  {item.properties.description && <p>{item.properties.description}</p>}
                  <Link to={`/geofence/${item.properties.id}`}>More details</Link>
                </Popup>
              </GeoJSON>
            ))}
          </LayerGroup>

          {!L.Browser.mobile && <FullscreenControl position={FULLSCREEN_CONTROL_POSITION} />}
        </Overlay>
      </LayersControl>
    </MapContainer>
  );
}
