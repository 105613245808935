import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { Tabs, Typography } from 'antd';

import { setSelectedIds, setSliderData, setTimeSlidersVisible } from '../../../redux/reducers/animal';
import { setMapData } from '../../../redux/reducers/map';

import MapTracking from '../../../components/Maps/MapTracking';
import TimeSliders from '../../../components/TrackLivestock/TimeSliders';

import { DEFAULT_TAB, TAB } from './constants';

const { Title, Text } = Typography;
const LivestockTable = lazy(() => import('../../../components/TrackLivestock/LivestockTable'));
const Farms = lazy(() => import('../../../views/pages/farms'));
const Geofences = lazy(() => import('../../../views/pages/geofences'));
const Sites = lazy(() => import('../../../views/pages/sites'));

export default function Maps({ history }) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');
  const { data } = useSelector(state => state.map);
  const { mapData: livestockMapData, selectedIds, timeSlidersVisible } = useSelector(state => state.animal);
  const { mapData: geofenceMapData } = useSelector(state => state.geofence);
  const { mapData: siteMapData } = useSelector(state => state.site);
  const { mapData: farmMapData } = useSelector(state => state.farm);
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const [state, setState] = useState({
    animal: null,
    farm: null,
    filteredFarms: []
  });
  const items = [
    {
      key: TAB.LIVESTOCK,
      label: 'Livestock',
      children: (
        <Suspense>
          <LivestockTable />
        </Suspense>
      )
    },
    {
      key: TAB.GEOFENCE,
      label: 'Geofence',
      children: (
        <Suspense>
          <Geofences />
        </Suspense>
      )
    },
    {
      key: TAB.SITE,
      label: 'Site',
      children: (
        <Suspense>
          <Sites />
        </Suspense>
      )
    },
    {
      key: TAB.FARM,
      label: 'Farm',
      children: (
        <Suspense>
          <Farms />
        </Suspense>
      )
    }
  ];

  useEffect(() => {
    return () => {
      dispatch(setTimeSlidersVisible());
      dispatch(setSelectedIds([]));
    };
  }, []);

  useEffect(() => {
    storeMapData();
  }, [geofenceMapData, siteMapData, farmMapData, livestockMapData]);

  useEffect(() => {
    setActiveTab(tab || DEFAULT_TAB);
  }, [tab]);

  useEffect(() => {
    storeMapData();
  }, [activeTab]);

  function storeMapData() {
    switch (activeTab) {
      case TAB.GEOFENCE:
        dispatch(setMapData(geofenceMapData));
        break;
      case TAB.SITE:
        dispatch(setMapData(siteMapData));
        break;
      case TAB.FARM:
        dispatch(setMapData(farmMapData));
        break;
      case TAB.LIVESTOCK:
      default:
        dispatch(setMapData(livestockMapData));
        break;
    }
  }

  function setAnimal(id) {
    history.push(`/tracking/${id}`);
  }

  function sliderHandler(value, field) {
    dispatch(setSliderData({ value, field }));
  }

  function handleChangeTab(tab) {
    setActiveTab(tab);
    dispatch(setTimeSlidersVisible());
    dispatch(setSelectedIds([]));
  }

  return (
    <div className="card p-2">
      <div>
        <Title level={4}>Maps</Title>
        <Breadcrumb>
          <BreadcrumbItem>
            <Text type="secondary">All maps and geo-locations</Text>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <MapTracking
        activeTab={activeTab}
        farms={!state.animal ? data?.farms : null}
        key={JSON.stringify(data)}
        recordsWithGeoData={data?.recordsWithGeoData}
        tracking={state.animal ? data?.animal_tracking_filtered : data?.tracking}
        onAnimalClick={setAnimal}
      />

      {timeSlidersVisible && selectedIds?.length > 0 && <TimeSliders sliderHandler={sliderHandler} />}

      <Tabs defaultActiveKey={tab || DEFAULT_TAB} items={items} onChange={tab => handleChangeTab(tab)} />
    </div>
  );
}
