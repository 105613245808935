import axios from '../../axios';

const path = 'geofences';

const service = {
  async fetchGeofences(params) {
    return axios({ method: 'get', url: path, params });
  },

  async fetchGeofence(id) {
    return axios({ method: 'get', url: `${path}/${id}` });
  },

  async updateGeofence(id, data) {
    return axios({ method: 'put', url: `${path}/${id}`, data });
  },

  async deleteGeofences(ids) {
    return axios({ method: 'delete', url: `${path}/bulk`, data: { ids } });
  },

  async deleteGeofence(id) {
    return axios({ method: 'delete', url: `${path}/${id}` });
  }
};

export default service;
