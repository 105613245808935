import React, { memo } from 'react';
import { Circle, Marker, Polygon, Polyline, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';

import * as turf from '@turf/turf';
import L from 'leaflet';

const getIconMarker = color => {
  return new L.divIcon({
    // iconUrl: require('../assets/images/pin.png'),
    // iconRetinaUrl: require('../assets/images/pin@2x.png'),
    html: `<span class="custom-marker" style="background: ${color}"/>`,
    className: 'marker',
    iconAnchor: [14, 22],
    labelAnchor: [-8, 0],
    popupAnchor: [0, -16]
    // iconAnchor: L.Browser.retina ? [12, 28] : [6, 14]
  });
};

function SiteFeature(props) {
  const type = props.type;
  const color = props.color || '#fff';
  const fillColor = props.color || 'none';
  const fillOpacity = props.new ? '0.5' : '0.3';
  const iconMarker = getIconMarker(props.color);

  let LINE;
  const popup = (
    <Popup>
      <b>{props.properties.name}</b>
      <br />
      <small className="capitalize">{props.properties.site_type}</small>
      <br />
      <br />
      {props.properties.description}
      <br />
      <br />
      <Link to={`/site/${props.properties.id}`}>
        <span>More details</span>
      </Link>
    </Popup>
  );
  if (type === 'Polygon') {
    let coords = JSON.parse(JSON.stringify(props.geometry));
    for (let coord in coords[0]) {
      let temp = coords[0][coord];
      let newc = [];
      newc[0] = temp[1];
      newc[1] = temp[0];
      coords[0][coord] = newc;
    }
    const features = turf.featureCollection([
      turf.point(coords[0][0]),
      turf.point(coords[0][1]),
      turf.point(coords[0][2]),
      turf.point(coords[0][3])
    ]);
    const center = turf.center(features);
    LINE = (
      <Polygon color={color} weight={2} fillOpacity={fillOpacity} fillColor={fillColor} opacity="1" positions={coords}>
        <Marker icon={iconMarker} draggable={false} position={center.geometry.coordinates}>
          {popup}
        </Marker>
      </Polygon>
    );
  }
  if (type === 'LineString') {
    LINE = (
      <Polyline color={color} fillOpacity={fillOpacity} fillColor={fillColor} positions={props.geometry}></Polyline>
    );
  }
  if (type === 'Point') {
    LINE = (
      <Circle
        center={[props.geometry[1], props.geometry[0]]}
        color={color}
        weight={2}
        fillOpacity={fillOpacity}
        fillColor={fillColor}
        opacity="1"
        radius={!isNaN(props.properties.radius) ? props.properties.radius : 1}>
        {' '}
        <Marker icon={iconMarker} draggable={false} position={[props.geometry[1], props.geometry[0]]}>
          {popup}
        </Marker>
      </Circle>
    );
  }
  return LINE;
}

export default memo(SiteFeature);
