import { createSlice } from '@reduxjs/toolkit';

import { deleteSites, fetchSites } from '../actions/site';

const siteSlice = createSlice({
  name: 'site',
  initialState: {
    data: [],
    error: null,
    loading: false,
    mapData: {},
    selectedFilters: {}
  },
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setMapData: (state, action) => {
      state.mapData = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Fetch Sites
      .addCase(fetchSites.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Sites
      .addCase(deleteSites.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSites.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(record => !action.payload?.includes(record.id));
      })
      .addCase(deleteSites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setSelectedFilters, setMapData } = siteSlice.actions;
export default siteSlice.reducer;
