import React from 'react';

import { Button, Typography } from 'antd';

const { Title, Text } = Typography;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to your error tracking service
    this.logError(error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
  }

  logError = (error, errorInfo) => {
    // Implement your error logging logic here
    console.error('Error caught by boundary:', {
      error,
      errorInfo,
      location: window.location.href,
      timestamp: new Date().toISOString()
    });
  };

  render() {
    if (this.state.hasError) {
      return this.props.fallback ? (
        this.props.fallback(this.state.error)
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
          <div className="w-50">
            <Title level={2}>Sorry, something went wrong 😔</Title>
            <details className="py-4">
              <summary>
                <Text mark>Error details</Text>
              </summary>
              <pre>{this.state.error?.toString()}</pre>
              {this.state.errorInfo?.componentStack && (
                <pre className="mt-2">{this.state.errorInfo.componentStack}</pre>
              )}
            </details>
            <div className="d-flex justify-content-end">
              <Button type="primary">Go to Dashboard</Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
