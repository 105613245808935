import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import moment from 'moment';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../../helpers/filter';

import { DATE_FORMAT } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import { NOTIFICATION_STATUS } from '../../constants/notifications';

import { setNotificationList } from '../../redux/actions/notification';
import { setSelectedFilters } from '../../redux/reducers/animal';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import Priority from '../../components/PriorityTag';
import Filters from '../../components/filters/index.jsx';

import { NotificationService } from '../../services';

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      add_modal: false,
      columnFilterOptions: {
        priority: [],
        status: [],
        type: []
      },
      delete_modal: false,
      filteredInfo: {},
      filters: {
        animals: [],
        farms: props?.location?.filter?.farms || [],
        geofences: [],
        labels: [],
        rules: [],
        sites: [],
        tags: []
      },
      isLoading: true,
      isOpenSelectionPopover: false,
      livestock_opts: [],
      modal_message: '',
      modal_title: '',
      modal_type: '',
      notifications: [],
      query: '',
      rules_opts: [],
      selectAll: false,
      selected_livestock: [],
      selected_notifications: [],
      selected_rules: [],
      selectedFilters: [],
      tableData: []
    };

    this.bulkOperations = this.bulkOperations.bind(this);
    this.clearAllNotifications = this.clearAllNotifications.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.clearNotification = this.clearNotification.bind(this);
    this.clearNotifications = this.clearNotifications.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.getLivestock = this.getLivestock.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.getRules = this.getRules.bind(this);
    this.initActions = this.initActions.bind(this);
    this.onNotSelect = this.onNotSelect.bind(this);
    this.selectRecords = this.selectRecords.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.setSelectedFilters = this.props.setSelectedFilters.bind(this);
    this.snoozeNotification = this.snoozeNotification.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.getLivestock();
    this.getRules();
    this.getNotifications();
    this.initActions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selected_notifications !== this.state.selected_notifications ||
      prevProps.selectedFilters !== this.props.selectedFilters
    ) {
      this.initActions();
    }
  }

  clearFilters = () => {
    this.setState(prevState => ({ ...prevState, filteredInfo: {} }));
    this.setSelectedFilters({});
  };

  setFilters = (pagination, filters, sorter) => {
    this.setState(prevState => ({ ...prevState, filteredInfo: filters }));
    this.setSelectedFilters(filters);
  };

  selectRecords(selected) {
    this.setState(prevState => ({
      ...prevState,
      selected_notifications: selected,
      selectAll: prevState.selected_notifications.length === selected.length
    }));
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => this.clearNotifications()}>
              Clear notifications
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => this.toggleModal('delete_modal', 'Snooze')}>
              Snooze notifications
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => this.clearAllNotifications()}>
              Clear all new notifications
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => this.snoozeAllNotifications()}>
              Snooze all new notifications
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!Object.values(this.props.selectedFilters)?.length}
              style={{ padding: 0 }}
              onClick={() => this.clearFilters()}>
              Clear all filters
            </Button>
          )
        }
      ]
    });
  }

  onChange = (value, field) => {
    this.setState(state => ({ ...state, [field]: value }));
  };

  handleKeyPress = charCode => {
    if (charCode === 13) this.getNotifications();
  };

  async getNotifications() {
    const response = await axios.get('notifications', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms.map(x => x.value),
        label_ids: this.state.filters.labels.map(x => x.value),
        geofence_ids: this.state.filters.geofences.map(x => x.value),
        site_ids: this.state.filters.sites.map(x => x.value),
        animal_ids: this.state.filters.animals.map(x => x.value),
        tag_ids: this.state.filters.tags.map(x => x.value),
        rule_ids: this.state.filters.rules.map(x => x.value)
      }
    });

    this.setState(prevState => ({
      ...prevState,
      isLoading: false,
      notifications: response.data.notifications,
      notificationsCount: response.data.count,
      tableData: response.data.notifications?.slice(0, 25),
      selectAll: false,
      selected_notifications: [],
      columnFilterOptions: {
        ...prevState.columnFilterOptions,
        priority: getFilterOptions(response?.data?.notifications, 'priority'),
        status: getFilterOptions(response?.data?.notifications, 'status'),
        type: getFilterOptions(response?.data?.notifications, 'type')
      }
    }));
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  toggleModal(modal, type) {
    let title = '';
    let message = '';

    if (type === 'Delete') {
      title = 'Delete Notifications';
      message = 'Are you sure you want to delete selected Notifications? This action cannot be undone.';
    } else if (type === 'Snooze') {
      title = 'Snooze notifications';
      message = 'Are you sure you want to snooze selected notifications? This action cannot be undone.';
    } else {
      title = 'Unsnooze notifications';
      message = 'Are you sure you want to Unsnooze selected notifications? This action cannot be undone.';
    }

    this.setState(state => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }

  async getRules() {
    const response = await axios.get('rules');

    this.setState({ rules_opts: response.data });
  }

  async getLivestock() {
    const response = await axios.get('animals');

    this.setState({ livestock_opts: response.data });
  }

  onNotSelect(not) {
    const selected_notifications = this.state.selected_notifications;

    if (selected_notifications.includes(not)) {
      const index = selected_notifications.indexOf(not);

      if (index > -1) selected_notifications.splice(index, 1);
    } else {
      selected_notifications.push(not);
    }

    this.setState({ selectAll: this.state.notifications.length === this.state.selected_notifications.length });
    this.setState(state => ({ ...state, selected_notifications: selected_notifications }));
  }

  async snoozeNotification(id) {
    const response = await axios.put('notifications/' + id + '/snooze');

    if (response.status === 200) this.getNotifications();
  }

  async clearNotification(id) {
    const response = await axios.put('notifications/' + id + '/clear');

    if (response.status === 200) {
      this.getNotifications();
      const updatedNotificationList = this.props.notifications.filter(notif => notif.id !== id);
      this.props.setNotificationList(updatedNotificationList);
    }
  }

  async clearNotifications() {
    const response = await axios.put('notifications/clear', {
      ids: this.state.selected_notifications
    });

    if (response.status === 200) {
      const updatedNotificationList = this.props.notifications.filter(
        notif => !this.state.selected_notifications.includes(notif.id)
      );
      this.props.setNotificationList(updatedNotificationList);
      this.setState({ selectAll: false, selected_notifications: [] });
      this.getNotifications();
    }
  }

  async bulkOperations() {
    const response = await axios.put('notifications/snooze', {
      ids: this.state.selected_notifications,
      force: true,
      seconds: 86400
    });

    if (response.status === 200) {
      this.setState({ delete_modal: false, selectAll: false, selected_notifications: [] });
      this.getNotifications();
    }
  }

  async clearAllNotifications() {
    const response = await NotificationService.clearAllNotifications();

    if (response.status === 200) {
      this.props.setNotificationList([]);
      this.setState({ selectAll: false, selected_notifications: [] });
      this.getNotifications();
    }
  }

  async snoozeAllNotifications() {
    const response = await NotificationService.snoozeAllNotifications();

    if (response.status === 200) {
      this.setState({ selectAll: false, selected_notifications: [] });
      this.getNotifications();
    }
  }

  filtersUpdated(filters) {
    this.setState({ filters: filters }, () => this.getNotifications());
  }

  filtersClose() {
    this.setState({ filters_open: false });
  }

  render() {
    const commonColumnProperties = {
      ellipsis: true,
      sortDirections: ['ascend', 'descend']
    };

    const columns = [
      {
        ...commonColumnProperties,
        title: 'Notification ID',
        dataIndex: 'identifier',
        filteredValue: this.state.filteredInfo.identifier || null,
        searchable: true,
        width: COLUMN_SIZE.SM,
        sorter: (a, b) => sortStrings(a.identifier, b.identifier),
        render: (value, record) => <Link to={`notification/${record.id}`}>{record.identifier}</Link>
      },
      {
        ...commonColumnProperties,
        title: 'Notification Priority',
        dataIndex: 'priority',
        filteredValue: this.state.filteredInfo.priority || null,
        filters: this.state.columnFilterOptions.priority,
        width: COLUMN_SIZE.MD,
        onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.priority : record?.priority === value),
        render: (value, record) => <Priority priority={record.priority} isClear={record.cleared_at}></Priority>,
        sorter: (a, b) => sortNumbers(a.priority_index, b.priority_index)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Type',
        dataIndex: 'type',
        filteredValue: this.state.filteredInfo.type || null,
        filters: this.state.columnFilterOptions.type,
        width: COLUMN_SIZE.LG,
        onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.type : record?.type === value),
        render: value => capitalize(value),
        sorter: (a, b) => sortStrings(a.type, b.type)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Rule',
        dataIndex: 'ruleName',
        filteredValue: this.state.filteredInfo.ruleName || null,
        searchable: true,
        width: COLUMN_SIZE.XXL,
        onFilter: (value, record) => record.rule?.name?.toLowerCase().includes(value.toLowerCase()),
        render: (value, record) => record?.rule?.name,
        sorter: (a, b) => sortStrings(a?.rule?.name, b?.rule?.name)
      },
      {
        ...commonColumnProperties,
        title: 'Livestock Count',
        dataIndex: 'animals',
        filteredValue: this.state.filteredInfo.animals || null,
        searchable: true,
        width: COLUMN_SIZE.SM,
        onFilter: (value, record) => record.animals?.length === +value,
        render: (value, record) => record.animals?.length ?? 0,
        sorter: (a, b) => sortNumbers(a?.animals?.length, b?.animals?.length)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Trigger Date',
        dataIndex: 'created_at',
        filteredValue: this.state.filteredInfo.created_at || null,
        searchable: true,
        width: COLUMN_SIZE.XL,
        render: (value, record) => moment(record.created_at).format(DATE_FORMAT.DATETIME),
        sorter: (a, b) => sortDates(a.created_at, b.created_at)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Status',
        dataIndex: 'notificationStat',
        filteredValue: this.state.filteredInfo.notificationStat || null,
        filters: this.state.columnFilterOptions.status.map(option => ({
          ...option,
          text: NOTIFICATION_STATUS[option.text.toLowerCase()]
        })),
        width: COLUMN_SIZE.XL,
        onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.status : record?.status === value),
        render: (value, record) => NOTIFICATION_STATUS[record.status],
        sorter: (a, b) => {
          const strA = NOTIFICATION_STATUS[a.status];
          const strB = NOTIFICATION_STATUS[b.status];

          return sortStrings(strA, strB);
        }
      },
      {
        title: 'Snooze / Clear',
        align: 'center',
        ellipsis: true,
        width: COLUMN_SIZE.XS,
        render: (value, record) => (
          <>
            <i
              onClick={() => this.snoozeNotification(record.id)}
              className={'not-action mdi mdi-alarm ' + record.status}></i>
            <i
              onClick={() => this.clearNotification(record.id)}
              className={'not-action clear mdi mdi-check-circle-outline ' + !!record.cleared_at}></i>
          </>
        )
      }
    ];

    return (
      <>
        <Filters
          farms={this.state.filters.farms}
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['geofence', 'site', 'animal', 'tag', 'rule', 'farm']}
        />

        <div className="card p-2">
          <AppTable
            headerClass="py-2"
            breadcrumb="List of Notifications"
            searchPlaceholder="Search notifications"
            title="Notifications"
            actions={this.state.actions}
            baseColumns={columns}
            dataSource={this.state.notifications}
            filterable={false}
            loading={this.state.isLoading}
            rowClassName={record => (record.cleared_at ? 'row-secondary' : '')}
            searchDefaultValue={this.state.query}
            totalCount={this.state.notifications?.length}
            handleFilters={() => this.setState({ ...this.state, filters_open: !this.state.filters_open })}
            handleOnChange={this.setFilters}
            handleOnSelectRecords={this.selectRecords}
            handleSearchChange={e => this.onChange(e.target.value, 'query')}
            handleSearchKeyPress={() => this.handleKeyPress(13)}
          />
        </div>

        <AppModal
          isOpen={this.state.delete_modal}
          confirmButtonText={this.state.modal_type}
          title={this.state.modal_title}
          handleCancel={() => this.toggleModal('delete_modal')}
          handleConfirm={() => this.bulkOperations()}>
          <div className="py-4">{this.state.modal_message}</div>
        </AppModal>
      </>
    );
  }
}

export default connect(
  state => ({
    selectedFilters: state.notification.selectedFilters,
    notifications: state.notification.list
  }),
  dispatch => ({
    setNotificationList: data => {
      dispatch(setNotificationList(data));
    },
    setSelectedFilters: filters => {
      dispatch(setSelectedFilters(filters));
    }
  })
)(Notifications);
