import React, { memo, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { FilterFilled, SettingFilled } from '@ant-design/icons';
import { Button, Radio, Typography } from 'antd';
import classnames from 'classnames';

import { TABLE_SIZE } from '../../components/AppTable/constants';
import AppSearch from '../../components/Search/Search';

import MultiSelectDropdown from '../../views/ui-components/MultiSelectDropdown';
import AppDropdown from '../../views/ui-components/dropdown';

const { Title, Text } = Typography;

function AppTableControls({
  actions,
  headerClass,
  columns,
  filterable = false,
  loading,
  searchable = true,
  settings = true,
  searchDefaultValue,
  searchPlaceholder = 'Search',
  title,
  breadcrumb,
  handleFilters,
  handleTableSize,
  handleCheckedList,
  handleSearchChange,
  handleSearchKeyPress
}) {
  const [size, setSize] = useState(TABLE_SIZE.SM);
  const [isSettingsOpen, setSettings] = useState(false);

  function choseVisibleColumns(columns) {
    handleCheckedList(columns);
  }

  function choseTableSize(size) {
    setSize(size);
    handleTableSize(size);
  }

  function toggleSettings() {
    setSettings(prevState => !prevState);
  }

  return (
    <>
      <div className={classnames('d-flex flex-wrap justify-content-between align-items-center', headerClass)}>
        <div>
          {title && <Title level={4}>{title}</Title>}
          {breadcrumb && (
            <Breadcrumb>
              <BreadcrumbItem>
                <Text type="secondary">{breadcrumb}</Text>
              </BreadcrumbItem>
            </Breadcrumb>
          )}
        </div>
        <div className="d-flex flex-grow-1 justify-content-end align-items-center flex-nowrap">
          {searchable && (
            <AppSearch
              className="mr-2"
              id="search"
              defaultValue={searchDefaultValue}
              handleChange={handleSearchChange}
              loading={false}
              placeholder={searchPlaceholder}
              handleKeyPress={handleSearchKeyPress}
            />
          )}

          {actions && (
            <AppDropdown
              className="mr-2"
              label="Actions"
              items={actions}
              handleClick={action => {
                if (action.handler) {
                  return action.handler();
                }
              }}
            />
          )}

          {settings && (
            <Button color="primary" size="large" variant="solid" icon={<SettingFilled />} onClick={toggleSettings}>
              Settings
            </Button>
          )}

          {filterable && (
            <Button
              className="ml-2"
              color="primary"
              size="large"
              variant="solid"
              icon={<FilterFilled />}
              onClick={handleFilters}>
              Filters
            </Button>
          )}
        </div>
      </div>
      {settings && isSettingsOpen && (
        <div className="d-flex justify-content-end pt-2 pb-4 table-header">
          <Radio.Group
            buttonStyle="solid"
            className="mr-2 custom-radio-group d-flex flex-nowrap"
            optionType="button"
            value={size}
            onChange={e => choseTableSize(e.target.value)}>
            <Radio.Button value={TABLE_SIZE.SM}>Small</Radio.Button>
            <Radio.Button value={TABLE_SIZE.MD}>Medium</Radio.Button>
            <Radio.Button value={TABLE_SIZE.LG}>Large</Radio.Button>
          </Radio.Group>

          <MultiSelectDropdown columns={columns} onChange={choseVisibleColumns} />
        </div>
      )}
    </>
  );
}

export default memo(AppTableControls);
