import { createAsyncThunk } from '@reduxjs/toolkit';

import { SiteService } from '../../services';

export const fetchSites = createAsyncThunk('site/fetchSites', async (params, { rejectWithValue }) => {
  try {
    const response = await SiteService.fetchSites(params);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch sites');
  }
});

export const deleteSites = createAsyncThunk('site/deleteSites', async (ids, { rejectWithValue }) => {
  try {
    await SiteService.deleteSites(ids);

    return ids;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete sites');
  }
});
