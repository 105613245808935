import { memo } from 'react';
import { Polygon } from 'react-leaflet';

import { GEOMETRY_TYPE } from '../../constants/map';

function Feature({ children, color, geometry, id, type, setAnimal }) {
  if (type === GEOMETRY_TYPE.POLYGON) {
    return (
      <Polygon
        fillOpacity="0.5"
        opacity="0.5"
        color={color}
        key={id}
        positions={geometry}
        onClick={() => setAnimal(type)}>
        {children}
      </Polygon>
    );
  }
}

export default memo(Feature);
