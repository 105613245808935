import axios from '../../axios';

const service = {
  async fetchSites(params) {
    return axios({
      method: 'get',
      url: '/sites',
      params
    });
  },

  async deleteSites(ids) {
    return axios({
      method: 'delete',
      url: 'sites/bulk',
      data: { ids }
    });
  }
};

export default service;
