import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button } from 'antd';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import moment from 'moment';
import * as XLSX from 'xlsx';

import axios from '../../axios';

import { capitalize, downloadCSV } from '../../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../../helpers/filter';

import { BREEDING_EVENT_TYPE } from '../../constants/breeding';
import { DATE_FORMAT, EDIT_COMPONENT_TYPE, KEY_PRESS_CODE, SEARCH_DEBOUNCE_DELAY } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import messages from '../../constants/messages';
import { BREEDING_CSV_TEMPLATE } from '../../constants/templates';

import { fetchAnimals } from '../../redux/actions/animal';
import { fetchBreeds } from '../../redux/actions/breed';
import {
  deleteBreedings,
  fetchBreeding,
  fetchBreedingEventStatusTypes,
  fetchBreedingEventTypes,
  setSelectedFilters
} from '../../redux/actions/breeding';
import { fetchMatingEventTypes } from '../../redux/actions/mating-event';
import { fetchMeasurementTypes } from '../../redux/actions/measurement-type';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import CreateBreedingModal from '../../components/Breeding/CreateBreedingModal';
import CalvRegistrationModal from '../../components/Modals/CalvRegistrationModal';
import UploadModal from '../../components/Modals/UploadModal';

import { errorToastHandler, successToastHandler } from '../action_notifier';

function BreedingTable(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { data: measurementTypes, loading: measurementTypesLoading } = useSelector(state => state.measurementType);
  const { data: animals, calfAry, sireAry } = useSelector(state => state.animal);
  const { breedsSelectOptions } = useSelector(state => state.breed);
  const {
    breedingEventTypesSelectOptions,
    breedingEventStatusTypesSelectOptions,
    data: breeding,
    loading: breedingLoading,
    selectedFilters
  } = useSelector(state => state.breeding);
  const { selectOptions: matingTypes } = useSelector(state => state.matingEvent);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [initTableData, setInitTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [state, setState] = useState({
    bulk_modal: false,
    calv_registration_animal: null,
    calv_registration_modal: false,
    columnFilterOptions: {
      breedingStatuses: [],
      breeds: [],
      sex: [],
      types: []
    },
    common_data: {
      breeding_plan: '',
      breeding_status_id: [],
      calf_id: [],
      calving_performance: '',
      comments: '',
      cost: '0',
      event_description: '',
      event_end_date: '',
      event_start_date: '',
      event_type_id: [],
      expected_calving_date: '',
      is_pregnant: 0,
      maternity_performance: '',
      mating_type_id: [],
      sire_id: [],
      straw_ref: '',
      straws: ''
    },
    filters: {
      farms: [],
      geofences: [],
      labels: []
    },
    query: '',
    selectedIds: [],
    tableData: [],
    treatmentTypes: [],
    treatmentUOM: []
  });

  async function fetchData() {
    try {
      await Promise.all([
        getTreatmentTypes(),
        getTreatmentUOM(),
        getBreeding(),
        dispatch(fetchBreedingEventTypes()),
        dispatch(fetchMeasurementTypes()),
        dispatch(fetchBreeds()),
        dispatch(fetchBreedingEventStatusTypes()),
        dispatch(fetchMatingEventTypes()),
        dispatch(fetchAnimals())
      ]);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    initActions();
  }, []);

  useEffect(() => {
    if (breeding) {
      setInitTableData([...breeding]);
      setState(prevState => ({
        ...prevState,
        columnFilterOptions: {
          ...prevState.columnFilterOptions,
          breedingStatuses: getFilterOptions(breeding, 'breeding_status_name'),
          breeds: getFilterOptions(breeding, 'breed'),
          sex: getFilterOptions(breeding, 'sex'),
          types: getFilterOptions(breeding, 'breeding_event_name')
        }
      }));

      prepareTableData();
    }
  }, [breeding, animals, matingTypes]);

  useEffect(() => {
    initActions();
  }, [state.selectedIds, selectedFilters]);

  function initActions() {
    setActions([
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!params.id && !state.selectedIds?.length}
            style={{ padding: 0 }}
            onClick={() => toggleModal('bulk_modal')}>
            Add breeding
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            style={{ padding: 0 }}
            onClick={() => toggleModal('csv_modal')}>
            Upload breeding
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.selectedIds?.length}
            style={{ padding: 0 }}
            onClick={() => downloadCSVFile()}>
            Download breeding list
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.selectedIds?.length}
            style={{ padding: 0 }}
            onClick={() => toggleModal('delete_modal')}>
            Archive breeding
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!Object.values(selectedFilters)?.length}
            style={{ padding: 0 }}
            onClick={() => clearFilters()}>
            Clear all filters
          </Button>
        )
      }
    ]);
  }

  function getBreeding() {
    const animalId = params.id ? +params.id : null;
    const reqParams = animalId
      ? {
          livestock_ids: animalId,
          breeding_ids: animalId
        }
      : {
          query: state.query,
          farm_ids: state.filters?.farms?.map(x => x.value),
          label_ids: state.filters?.labels?.map(x => x.value),
          geofence_ids: state.filters?.geofences?.map(x => x.value)
        };

    dispatch(fetchBreeding(reqParams));
  }

  async function downloadCSVFile() {
    if (state.selectedIds?.length) {
      try {
        const query = `breeding_ids=${state?.selectedIds}`;
        const response = await axios.get(`/breeding/export?${query}`, {
          responseType: 'blob'
        });
        downloadCSV(response.data, `Breeding_${moment().format(DATE_FORMAT.DATE)}.xlsx`);
      } catch (error) {
        errorToastHandler(messages.DEFAULT_ERROR);
      }
    }
  }

  async function getTreatmentTypes() {
    const response = await axios.get('treatment_types');

    setState(prevState => ({
      ...prevState,
      treatmentTypes: response.data.map(x => ({ label: x.treatment_type_name, value: x.id }))
    }));
  }

  async function getTreatmentUOM() {
    const response = await axios.get('treatment_uom');

    setState(prevState => ({
      ...prevState,
      treatmentUOM: response.data.map(x => ({ label: x.uom_description, value: x.uom_code }))
    }));
  }

  function deleteRecords() {
    const breedingIds = breeding?.filter(d => state.selectedIds.includes(d.id)).map(i => i.breeding_id);
    dispatch(deleteBreedings(breedingIds, state.selectedIds));

    if (!params.id) getBreeding();

    toggleModal('delete_modal');
  }

  function resetCommonData() {
    setState(prevState => ({
      ...prevState,
      common_data: {
        breeding_plan: '',
        breeding_status_id: structuredClone(breedingEventStatusTypesSelectOptions),
        calf_id: structuredClone(calfAry),
        calving_performance: '',
        comments: '',
        cost: '0',
        event_description: '',
        event_end_date: '',
        event_start_date: '',
        event_type_id: structuredClone(breedingEventTypesSelectOptions),
        expected_calving_date: '',
        is_pregnant: 0,
        maternity_performance: '',
        mating_type_id: structuredClone(matingTypes),
        sire_id: structuredClone(sireAry),
        straw_ref: '',
        straws: ''
      }
    }));
  }

  function toggleModal(modal) {
    resetCommonData();
    setState(prevState => ({ ...prevState, [modal]: !prevState[modal] }));
  }

  function handleCreateCalvingEventType(newData, response) {
    const calvingEventType = breedingEventTypesSelectOptions.find(({ label }) => label === BREEDING_EVENT_TYPE.CALVING);
    const isCreatingCalving = newData.find(({ event_type_id }) => event_type_id === calvingEventType?.value);

    if (isCreatingCalving) {
      const [breeding] = response.data.breeding_events;
      const animal = animals?.find(({ id }) => id === breeding.livestock_id);
      setState(prevState => ({ ...prevState, calv_registration_modal: true, calv_registration_animal: animal }));
    }
  }

  async function createBreedingForSelectedAnimals(data) {
    const records = initTableData?.filter(item => state.selectedIds.includes(item.id));
    const newData = records.map(record => ({
      ...data,
      event_end_date: data.event_end_date ? data.event_end_date.format(DATE_FORMAT.ISO_8601) : '',
      event_start_date: data.event_start_date ? data.event_start_date.format(DATE_FORMAT.ISO_8601) : '',
      expected_calving_date: data.expected_calving_date ? data.expected_calving_date.format(DATE_FORMAT.ISO_8601) : '',
      livestock_id: parseInt(record.livestock_id)
    }));

    const response = await axios.post('breeding/store/bulk', {
      breeding_events: JSON.stringify(newData)
    });

    if (response.status === 200) {
      handleCreateCalvingEventType(newData, response);
      await getBreeding();
    }
  }

  async function createBreedingForCurrentAnimal(data) {
    const newData = {
      ...data,
      event_end_date: data.event_end_date ? data.event_end_date.format(DATE_FORMAT.ISO_8601) : '',
      event_start_date: data.event_start_date ? data.event_start_date.format(DATE_FORMAT.ISO_8601) : '',
      expected_calving_date: data.expected_calving_date ? data.expected_calving_date.format(DATE_FORMAT.ISO_8601) : '',
      livestock_id: parseInt(params.id)
    };
    const response = await axios.post('breeding/store', newData);

    if (response.status === 200) {
      const calvingEventType = breedingEventTypesSelectOptions?.find(
        ({ label }) => label === BREEDING_EVENT_TYPE.CALVING
      );
      const isCreatingCalving = newData.event_type_id === calvingEventType.value;

      if (isCreatingCalving) {
        const breeding = response.data;
        const animal = animals?.find(({ id }) => id === breeding.livestock_id);
        setState(prevState => ({ ...prevState, calv_registration_modal: true, calv_registration_animal: animal }));
      }

      await getBreeding();
    }
  }

  function handleCreateBreeding(data) {
    if (!state.selectedIds?.length && !params.id) return;

    state.selectedIds?.length && !params.id
      ? createBreedingForSelectedAnimals(data)
      : createBreedingForCurrentAnimal(data);

    toggleModal('bulk_modal');
  }

  function filterData(filters) {
    setState(prevState => ({ ...prevState, filters: { ...state.filters, ...filters } }));
    getBreeding();
  }

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  function onChange(value, field) {
    setState(prevState => ({ ...prevState, [field]: value }));
  }

  const handleKeyPress = charCode => {
    if (charCode === KEY_PRESS_CODE) {
      debounceSearch(getBreeding);
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  const setFilters = (pagination, filters) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  const commonColumnProperties = {
    ellipsis: true,
    sortDirections: ['ascend', 'descend']
  };
  const columns = [
    {
      ...commonColumnProperties,
      title: 'Event ID',
      dataIndex: 'breeding_identifier',
      filteredValue: filteredInfo.breeding_identifier || null,
      fixed: params.id ? 'left' : undefined,
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.breeding_identifier, b.breeding_identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Event Type',
      dataIndex: 'breeding_event_name',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: breedingEventTypesSelectOptions
      },
      filteredValue: filteredInfo.breeding_event_name || null,
      filters: state.columnFilterOptions.types,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.breeding_event_name : record?.breeding_event_name === value,
      sorter: (a, b) => sortStrings(a.breeding_event_name, b.breeding_event_name)
    },
    {
      ...commonColumnProperties,
      title: 'Start Date',
      dataIndex: 'event_start_date',
      editConfig: { type: EDIT_COMPONENT_TYPE.DATE_PICKER },
      filteredValue: filteredInfo.event_start_date || null,
      rules: [{ required: true, message: 'Required.' }],
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortDates(a.event_start_date, b.event_start_date)
    },
    {
      ...commonColumnProperties,
      title: 'End Date',
      dataIndex: 'event_end_date',
      editConfig: { type: EDIT_COMPONENT_TYPE.DATE_PICKER },
      filteredValue: filteredInfo.event_end_date || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortDates(a.event_end_date, b.event_end_date)
    },
    {
      ...commonColumnProperties,
      title: 'Expected Calving Date',
      dataIndex: 'expected_calving_date',
      editConfig: { type: EDIT_COMPONENT_TYPE.DATE_PICKER },
      filteredValue: filteredInfo.expected_calving_date || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortDates(a.expected_calving_date, b.expected_calving_date)
    },
    {
      ...commonColumnProperties,
      title: 'Event Description',
      dataIndex: 'event_description',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.event_description || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.event_description, b.event_description)
    },
    {
      ...commonColumnProperties,
      title: 'Is Pregnant',
      dataIndex: 'is_pregnant',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 0 }
        ]
      },
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      filteredValue: filteredInfo.is_pregnant || null,
      width: COLUMN_SIZE.SM,
      onFilter: (value, record) => (value ? record.is_pregnant : !record.is_pregnant),
      sorter: (a, b) => {
        const strA = a.is_pregnant ? 'Yes' : '';
        const strB = b.is_pregnant ? 'Yes' : '';

        return sortStrings(strA, strB);
      }
    },
    {
      ...commonColumnProperties,
      title: 'Sire ID',
      dataIndex: 'sire_identifier_formatted',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: sireAry
      },
      filteredValue: filteredInfo.sire_identifier_formatted || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.sire_identifier_formatted, b.sire_identifier_formatted)
    },
    {
      ...commonColumnProperties,
      title: 'Calf ID',
      dataIndex: 'calf_identifier_formatted',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: calfAry
      },
      filteredValue: filteredInfo.calf_identifier_formatted || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.calf_identifier_formatted, b.calf_identifier_formatted)
    },
    {
      ...commonColumnProperties,
      title: 'Mating Type',
      dataIndex: 'mating_type_name',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: matingTypes
      },
      filteredValue: filteredInfo.mating_type_name || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.mating_type_name, b.mating_type_name)
    },
    {
      ...commonColumnProperties,
      title: 'Straws',
      dataIndex: 'straws',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.straws || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.straws, b.straws)
    },
    {
      ...commonColumnProperties,
      title: 'Straw Ref',
      dataIndex: 'straw_ref',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.straw_ref || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.straw_ref, b.straw_ref)
    },
    {
      ...commonColumnProperties,
      title: 'Cost',
      dataIndex: 'cost',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.cost || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortNumbers(a.cost, b.cost)
    },
    {
      ...commonColumnProperties,
      title: 'Breeding Status',
      dataIndex: 'breeding_status_name',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: breedingEventStatusTypesSelectOptions
      },
      filteredValue: filteredInfo.breeding_status_name || null,
      filters: state.columnFilterOptions.breedingStatuses,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.breeding_status_name : record?.breeding_status_name === value,
      sorter: (a, b) => sortStrings(a.breeding_status_name, b.breeding_status_name)
    },
    {
      ...commonColumnProperties,
      title: 'Calving Performance',
      dataIndex: 'calving_performance',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.calving_performance || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.calving_performance, b.calving_performance)
    },
    {
      ...commonColumnProperties,
      title: 'Maternity Performance',
      dataIndex: 'maternity_performance',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.maternity_performance || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.maternity_performance, b.maternity_performance)
    },
    {
      ...commonColumnProperties,
      title: 'Comments',
      dataIndex: 'comments',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.comments || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.comments, b.comments)
    },
    {
      ...commonColumnProperties,
      title: 'Breeding Plan',
      dataIndex: 'breeding_plan',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.breeding_plan || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.breeding_plan, b.breeding_plan)
    }
  ];
  const livestockColumns = [
    {
      ...commonColumnProperties,
      title: 'Livestock ID',
      dataIndex: 'identifier',
      filteredValue: filteredInfo.identifier || null,
      fixed: 'left',
      searchable: true,
      width: COLUMN_SIZE.MD,
      render: (value, record) => <Link to={`animal/${record.id}`}>{record.identifier}</Link>,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Mgmt Tag ID',
      dataIndex: 'eartag_management_id',
      filteredValue: filteredInfo.eartag_management_id || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      sorter: (a, b) => sortStrings(a.eartag_management_id, b.eartag_management_id)
    },
    {
      ...commonColumnProperties,
      title: 'Sex',
      dataIndex: 'sex',
      filteredValue: filteredInfo.sex || null,
      filters: state.columnFilterOptions?.sex,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.sex : record?.sex === value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.sex, b.sex)
    },
    {
      ...commonColumnProperties,
      title: 'Colour',
      dataIndex: 'colour',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      },
      filteredValue: filteredInfo.colour || null,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.colour, b.colour)
    },
    {
      ...commonColumnProperties,
      title: 'Breed',
      dataIndex: 'breed',
      filteredValue: filteredInfo.breed || null,
      filters: state.columnFilterOptions?.breeds,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.breed : record?.breed === value),
      sorter: (a, b) => sortStrings(a.breed, b.breed)
    }
  ];

  const tableColumns = params.id ? [...columns] : [...livestockColumns, ...columns];

  function prepareTableData() {
    const breedingCopy = structuredClone(breeding);
    const breedingEventTypesSelectOptionsCopy = structuredClone(breedingEventTypesSelectOptions);
    const sireAryCopy = structuredClone(sireAry);
    const calfAryCopy = structuredClone(calfAry);
    const matingTypesCopy = structuredClone(matingTypes);
    const rowData = breedingCopy?.map(row => {
      row.event_type_id = breedingEventTypesSelectOptionsCopy?.map(x => {
        x.isSelected = x.value === row.event_type_id;
        return x;
      });
      row.breeding_status_id = breedingEventTypesSelectOptionsCopy?.map(x => {
        x.isSelected = x.value === row.breeding_status_id;
        return x;
      });
      row.mating_type_id = matingTypesCopy.map(x => {
        x.isSelected = x.value === row.mating_type_id;
        return x;
      });
      row.sire_id = sireAryCopy
        ?.filter(x => x.value !== row.livestock_id)
        .map(x => {
          x.isSelected = x.value === row.sire_id;
          return x;
        });
      row.calf_id = calfAryCopy
        ?.filter(x => x.value !== row.livestock_id)
        .map(x => {
          x.isSelected = x.value === row.calf_id;
          return x;
        });

      return row;
    });

    setTableData([...rowData]);
    resetCommonData();
  }

  async function uploadCSV() {
    try {
      await axios.post('breeding/uploadCsv', { csv: state.csvfile });
      toggleModal('csv_modal');
      getBreeding();
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  }

  function handleCSVChange(event) {
    setState(prevState => ({ ...prevState, uploading: true }));

    const reader = new FileReader();
    const isXLSX = event.target.files?.[0]?.name?.includes('.xlsx');

    if (!isXLSX) reader.readAsText(event.target.files[0]);

    const rABS = !!reader.readAsBinaryString;

    reader.onload = function (e) {
      let data = '';

      if (isXLSX) {
        const wb = XLSX.read(e.target.result, {
          type: rABS ? 'binary' : 'array'
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        data = XLSX.utils.sheet_to_csv(ws, { strip: true });
      } else {
        data = reader.result;
      }

      setState(prevState => ({ ...prevState, csvfile: data, uploading: false }));
    };

    if (isXLSX) {
      rABS ? reader.readAsBinaryString(event.target.files[0]) : reader.readAsArrayBuffer(event.target.files[0]);
    }

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

  function selectRecords(selected) {
    setState(prevState => ({
      ...prevState,
      selectedIds: selected,
      selectAll: prevState.selectedIds?.length === selected?.length
    }));
  }

  async function handleChangeTableData(updatedData) {
    const record = updatedData.at(0);
    const initRecord = initTableData.find(i => i.id === record.id);
    let event_id = record.event_type_id.filter(x => x.isSelected === true);
    event_id = event_id.length ? event_id[0]['value'] : null;
    let status_id = record.breeding_status_id.filter(x => x.isSelected === true);
    status_id = status_id.length ? status_id[0]['value'] : null;
    let mating_id = record.mating_type_id.filter(x => x.isSelected === true);
    mating_id = mating_id.length ? mating_id[0]['value'] : null;

    const newData = {
      ...record,
      event_end_date: dayjs.isDayjs(record.event_end_date)
        ? dayjs(record.event_end_date).format(DATE_FORMAT.ISO_8601)
        : '',
      event_start_date: dayjs(record.event_start_date || initRecord.event_start_date).format(DATE_FORMAT.ISO_8601),
      expected_calving_date: dayjs.isDayjs(record.expected_calving_date)
        ? dayjs(record.expected_calving_date).format(DATE_FORMAT.ISO_8601)
        : '',
      id: isNaN(record.breeding_id) ? null : record.breeding_id,
      livestock_id: parseInt(record.livestock_id),
      event_type_id: record.breeding_event_name?.value || initRecord.event_type_id,
      breeding_status_id: status_id,
      event_id,
      status_id,
      mating_id: parseInt(record.mating_type_name?.value) || parseInt(initRecord.mating_type_name),
      mating_type_id: mating_id,
      sire_id: parseInt(record.sire_identifier_formatted?.value) || parseInt(initRecord.sire_id),
      calf_id: parseInt(record.calf_identifier_formatted?.value) || parseInt(initRecord.calf_id),
      is_pregnant: record.is_pregnant ? record.is_pregnant?.value : initRecord.is_pregnant
    };
    const response = await axios.put(`breeding/${record.breeding_id}`, newData);

    if (response.status === 200) {
      await getBreeding();
      successToastHandler(messages.UPDATED);
    }
  }

  return (
    <>
      <div className={props.className}>
        <AppTable
          searchPlaceholder="Search breeding"
          actions={actions}
          baseColumns={tableColumns}
          breadcrumb={props.breadcrumb}
          dataSource={tableData}
          headerClass="py-2"
          searchable={props.searchable}
          title={props.title}
          loading={loading || breedingLoading || measurementTypesLoading}
          searchDefaultValue={state.query}
          handleOnChange={setFilters}
          handleOnSelectRecords={selectRecords}
          handleSearchChange={e => onChange(e.target.value, 'query')}
          handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
          updateTableData={handleChangeTableData}
        />
      </div>

      <CreateBreedingModal
        data={state.common_data}
        isOpen={state.bulk_modal}
        handleCancel={() => toggleModal('bulk_modal')}
        handleConfirm={handleCreateBreeding}
      />

      <CalvRegistrationModal
        isOpen={state.calv_registration_modal}
        animal={state.calv_registration_animal}
        onCancel={() => toggleModal('calv_registration_modal')}
        commonData={state.common_data}
        measureTypes={measurementTypes}
        treatmentTypes={state.treatmentTypes}
        treatmentUOM={state.treatmentUOM}
        breeds={breedsSelectOptions}
      />

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        title="Archive breeding"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={deleteRecords}>
        <div className="py-4">
          Are you sure to archive these {state.selectedIds?.length === 1 ? 'record' : 'records'}?
        </div>
      </AppModal>

      <UploadModal
        filename="breeding-template.csv"
        isOpen={state.csv_modal}
        onUpload={uploadCSV}
        handleCSVChange={e => handleCSVChange(e)}
        template={BREEDING_CSV_TEMPLATE}
        onCancel={() => toggleModal('csv_modal')}
        description={
          <>
            <div>You can download the breeding list to input new breeding events.</div>
            <div>Download and save the file and then choose this file to upload new breeding events.</div>
          </>
        }
      />
    </>
  );
}

export default memo(BreedingTable);
