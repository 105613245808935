import { useCallback, useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import { Button, Typography } from 'antd';
import classnames from 'classnames';

import { numberWithCommas } from '../../helpers/common';
import { isFieldAvailable } from '../../helpers/subscription';

import { ADDRESS, KEY_MAP, ORDER, blacklist_edit, blacklist_full } from '../../constants/farm';
import messages from '../../constants/messages';
import { FEATURE_LIST, PLANS_TYPE } from '../../constants/subscription';

import { deleteFarm, fetchFarm, updateFarm } from '../../redux/actions/farm';

import Alerts from '../../components/Alerts';
import AppModal from '../../components/AppModal';
import CancelSaveButtons from '../../components/CancelSaveButtons';
import LoadingBar from '../../components/LoadingBar';
import { DEFAULT_SHAPE_COLOR } from '../../components/Maps/constants';
import Map from '../../components/Maps/map';
import PrevNextButtons from '../../components/PrevNextButtons';
import { errorToastHandler, successToastHandler } from '../../components/action_notifier';

import AppDropdown from '../ui-components/dropdown';

import countries from '../../countries.json';

const { Title, Text } = Typography;

function FarmRows(props) {
  if (props.farm === null) return null;

  const editing = props.editing;
  const farm = editing ? props.farm_edit : props.farm;
  const geoList = [];

  for (let geofence of farm.geofences) {
    if (!geofence.is_master) {
      geoList.push({ value: geofence.id, label: geofence.name, geofence: geofence });
    }
  }

  const FARM_INFO = [];
  FARM_INFO.push(
    <tr key={Math.random()}>
      <td colSpan="2">
        <Title level={4}>Farm {farm.name} Details</Title>
      </td>
    </tr>
  );

  let index = 0;
  const formattedOrder = ORDER.filter(fieldId =>
    isFieldAvailable(fieldId, props.subscription.myPlan?.type, FEATURE_LIST.FARM_DETAILS_FIELDS)
  );

  for (let key of formattedOrder) {
    let value = farm[key];

    if (blacklist_full.includes(key)) {
      continue;
    }

    if (key === 'address') {
      value = value.full_address;
    }

    if (key === 'house_number') {
      value = farm['address'];
      value = value.house_number;
    }

    if (key === 'city') {
      value = farm['address'];
      value = value.city;
    }

    if (key === 'postal_code') {
      value = farm['address'];
      value = value.postal_code;
    }

    if (key === 'country') {
      value = farm['address'];
      value = value.country;
    }

    if (key === 'herd_numbers') {
      value = value ? numberWithCommas(value) : '/';
    }

    if (key === 'size') {
      value = value ? numberWithCommas(value) : '/';
    }

    if (key === 'circumference') {
      value = value ? numberWithCommas(value) : '/';
    }

    if (Array.isArray(value)) {
      if (editing) {
        if (key !== 'geofences') {
          value = value.map(e => e.name);
          value = value.join(', ');
        }
      } else {
        value = value
          .filter(e => {
            if (!e.is_master) {
              return e;
            }
          })
          .map(e => e.name);
        value = value.join(', ');
      }
    }

    if (editing) {
      if (key === 'address') {
        key = 'street';
        value = farm['address'].street;
      }

      let INPUT = (
        <Input
          name={key}
          defaultValue={value}
          onChange={e => {
            props.onChange(e.target.value, e.target.name);
          }}
        />
      );

      if (ADDRESS.includes(key)) {
        INPUT = (
          <Input
            name={key}
            defaultValue={value}
            onChange={e => {
              props.onAddressChange(e.target.value, e.target.name);
            }}
          />
        );
      }

      if (key === 'geofences') {
        value = value
          .filter(e => {
            if (!e.is_master) {
              return e;
            }
          })
          .map(e => e.name);
        value = value.join(', ');
        INPUT = value;
      }

      if (key === 'description') {
        INPUT = (
          <Input
            type="textarea"
            name={key}
            defaultValue={value}
            onChange={e => {
              props.onChange(e.target.value, e.target.name);
            }}
          />
        );
      }

      if (key === 'country') {
        INPUT = (
          <Select
            value={countries.map(x => ({ value: x, label: x })).filter(x => x.value === value)}
            onChange={e => props.handleSelectChange(e, key)}
            options={countries.map(x => ({ value: x, label: x }))}
          />
        );
      }

      if (blacklist_edit.includes(key)) {
        INPUT = value;
      }

      FARM_INFO.push(
        <tr key={index}>
          <td className="v-align-middle">
            <b>{KEY_MAP[key]}</b>
          </td>
          <td>{INPUT}</td>
        </tr>
      );
    } else {
      if (value === 0) {
        FARM_INFO.push(
          <tr data-name={key} key={index}>
            <td className="v-align-middle">
              <b>{KEY_MAP[key]}</b>
            </td>
            <td>{value}</td>
          </tr>
        );
      } else {
        FARM_INFO.push(
          <tr data-name={key} key={index}>
            <td className="v-align-middle">
              <b>{KEY_MAP[key]}</b>
            </td>
            <td>{value || '/'}</td>
          </tr>
        );
      }
    }
    index++;
  }

  const colorPicker = (
    <tr>
      <td className="v-align-middle">
        <b>Farm Color</b>
      </td>
      <td>
        <CompactPicker name="colour" color={props.color} onChange={e => props.setColor(e.hex)}></CompactPicker>
      </td>
    </tr>
  );

  if (props.editing) {
    FARM_INFO.splice(3, 0, colorPicker);
  }

  return FARM_INFO;
}

export default function Farm(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const subscription = useSelector(state => state.subscription);
  const { farm, loading } = useSelector(state => state.farm);
  const [state, setState] = useState({
    actions: [],
    activeTab: '1',
    color: DEFAULT_SHAPE_COLOR,
    delete_modal: false,
    editing: false,
    farm: {
      // days_grazed: "",
      address: '',
      animals_count: '',
      circumference: '',
      description: '',
      full_address: '',
      geofence_id: '',
      geofences: [],
      id: null,
      name: '',
      rules: [],
      size: ''
    },
    farmId: null,
    farm_edit: {
      // days_grazed: "",
      address: '',
      animals_count: '',
      circumference: '',
      description: '',
      full_address: '',
      geofence_id: '',
      geofences: [],
      id: null,
      name: '',
      rules: [],
      size: ''
    },
    selected_geofences: []
  });

  useEffect(() => {
    getFarm();
    initActions();
  }, []);

  useEffect(() => {
    if (farm) {
      const masterGeofence = farm?.geofences?.find(item => item.is_master);

      setState(prevState => ({
        ...prevState,
        farmId: farm?.id,
        color: masterGeofence?.color,
        farm: structuredClone(farm),
        farm_edit: structuredClone(farm),
        selected_geofences: farm.geofences
          .filter(gf => !gf.is_master)
          .map(gf => ({ value: gf.id, label: gf.name, geofence: gf }))
      }));
    }
  }, [farm]);

  function initActions() {
    setState(prevState => ({
      ...prevState,
      actions: [
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => onChange(true, 'editing')}>
              Edit farm
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => toggleModal('delete_modal')}>
              Archive farm
            </Button>
          )
        }
      ]
    }));
  }

  function toggle(tab) {
    if (state.activeTab !== tab) setState(prevState => ({ ...prevState, activeTab: tab }));
  }

  const onFarmChangeAddress = (value, field) => {
    setState(prevState => ({
      farm_edit: {
        ...prevState.farm_edit,
        address: {
          ...prevState.farm_edit.address,
          [field]: value
        }
      }
    }));
  };

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
    // forceUpdate();
  };

  const onCancelEdit = (value, field) => {
    setState(prevState => ({ ...prevState, farm_edit: prevState.farm, [field]: value }));
  };

  const onFarmEditChange = (value, field) => {
    setState(prevState => ({ ...prevState, farm_edit: { ...prevState.farm_edit, [field]: value } }));
  };

  const onCountryChange = e => {
    const temp = { ...state.farm_edit };
    temp.address.country = e.value;
    setState(prevState => ({ ...prevState, temp }));
  };

  // const onMasterFenceUpdate = geoJSON => {
  //   console.log('onMasterFenceUpdate', geoJSON);
  //   const geofences = structuredClone(state.farm_edit.geofences);
  //   console.log('state.farm_edit', state.farm_edit);
  //   const index = geofences.findIndex(gf => gf.is_master);
  //
  //   geofences[index]['geo_json'] = geoJSON;
  //
  //   setState(prevState => ({
  //     ...prevState,
  //     farm_edit: { ...prevState.farm_edit, geofences },
  //     farm: { ...prevState.farm, geofences }
  //   }));
  // };

  const onMasterFenceUpdate = geoJSON => {
    setState(prevState => {
      const geofences = structuredClone(prevState.farm_edit.geofences);
      const index = geofences.findIndex(gf => gf.is_master);

      if (index === -1) return prevState;

      const updatedGeofences = geofences.map((g, i) => (i === index ? { ...g, geo_json: geoJSON } : g));

      return {
        ...prevState,
        farm_edit: { ...prevState.farm_edit, geofences: updatedGeofences },
        farm: { ...prevState.farm, geofences: updatedGeofences }
      };
    });
  };

  async function saveFarm() {
    const farmId = +params.id;
    const fences = state.farm_edit.geofences;
    const index = fences.findIndex(e => e.is_master);
    const farm = state.farm_edit;

    // EVENT TRIGGER FOR MAP 'SAVE' ACTION //
    // if (document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top')) {
    //   document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top li a').click();
    // }
    //
    // // WAIT FOR 200 MILISECONDS TO GET DATA //
    // await setTimeout(() => {}, 300);

    try {
      await dispatch(
        updateFarm({
          id: farmId,
          data: {
            id: farm.id,
            address: farm.address,
            name: farm.name,
            description: farm.description,
            color: state.color,
            herd_numbers: farm.herd_numbers !== null && farm.herd_numbers !== '' ? [farm.herd_numbers] : [],
            master_geofence: farm.geofences[index].geo_json,
            geofence_ids: state.selected_geofences.map(sg => sg.value).filter(Boolean)
          }
        })
      ).unwrap();
      await getFarm();
      successToastHandler(messages.UPDATED);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_UPDATE_DATA);
    } finally {
      setState(prevState => ({ ...prevState, editing: false }));
    }
  }

  const multiselectChange = sle => {
    setState(prevState => ({ ...prevState, selected_geofences: sle }));
  };

  async function removeFarm() {
    if (!params.id) return;

    try {
      await dispatch(deleteFarm(+params.id)).unwrap();
      successToastHandler(messages.ARCHIVED);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_DELETE_DATA);
    }

    props.history.push('/farms');
  }

  async function getFarm() {
    if (params?.id) {
      try {
        await dispatch(fetchFarm(+params.id)).unwrap();
      } catch (error) {
        errorToastHandler(error?.response?.data?.message || messages.FAILED_ON_FETCH_DATA);
      }
    }
  }

  const toggleModal = useCallback(
    modal => {
      setState(prevState => ({ ...prevState, [modal]: !prevState[modal] }));
    },
    [state.delete_modal]
  );

  function setAnimal() {
    props.history.push(`/tracking/${state.farm?.animals?.at(0)?.id}`);
  }

  return (
    <>
      {loading && !farm ? (
        <LoadingBar />
      ) : (
        <>
          <Row>
            <Col xs="6" md="6" lg="6">
              <Title level={4}>
                {state.editing && 'Edit '}Farm {state.farm.name}
              </Title>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/maps?tab=farm">
                    <Text type="success">List of Farms</Text>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Text type="secondary">
                    {state.editing && 'Edit '}Farm {state.farm.name}
                  </Text>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs="6" md="6" lg="6">
              <PrevNextButtons
                nextId={state.farm.next_id}
                prevId={state.farm.prev_id}
                handlePrev={() => props.history.push(`/farm/${state.farm.prev_id}`)}
                handleNext={() => props.history.push(`/farm/${state.farm.next_id}`)}
              />
              {state.editing && (
                <>
                  <CancelSaveButtons
                    handleCancel={() => onCancelEdit(false, 'editing')}
                    handleSave={() => saveFarm()}
                  />
                </>
              )}
              {!state.editing && <AppDropdown className="float-right" label="Actions" items={state.actions} />}
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12" lg="12">
              <Card>
                <Row>
                  <Col
                    xs="12"
                    md="12"
                    lg="12"
                    className={`
                    ${state.editing ? '' : 'rg-no-space'}
                    `}>
                    <Map
                      type="farm"
                      className={state.editing ? 'editing' : ''}
                      color={state.color}
                      editing={state.editing}
                      farm={state.farm}
                      sites={state.farm.sites}
                      editFence={onMasterFenceUpdate}
                      onCreateGeofence={onMasterFenceUpdate}
                      setAnimal={setAnimal}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12" lg="12" className="global-table">
              <Card>
                <Row>
                  <Col xs="12" md="12" lg="12">
                    <Table>
                      <tbody>
                        <FarmRows
                          key={1}
                          color={state.color}
                          setColor={color => setState(prevState => ({ ...prevState, color }))}
                          farm={state.farm}
                          farm_edit={state.farm_edit}
                          onAddressChange={onFarmChangeAddress}
                          editing={state.editing}
                          onChange={onFarmEditChange}
                          multiselectChange={multiselectChange}
                          selected_geofences={state.selected_geofences}
                          handleSelectChange={onCountryChange}
                          subscription={subscription}
                        />
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {state.editing && (
            <Row className="mb-4">
              <Col lg="12">
                <CancelSaveButtons handleCancel={() => onCancelEdit(false, 'editing')} handleSave={() => saveFarm()} />
              </Col>
            </Row>
          )}
          {[PLANS_TYPE.ADVANCED, PLANS_TYPE.BASIC, PLANS_TYPE.TRIAL].includes(subscription.myPlan?.type) ? null : (
            <Card>
              <Row className="pad-10 pb-0">
                <Col sm="12" className="mb-0">
                  <h5>History</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Nav tabs className="fancy-tabs">
                    <NavItem>
                      <NavLink className={classnames({ active: state.activeTab === '1' })} onClick={() => toggle('1')}>
                        Notifications
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <TabContent className="no-bg" activeTab={state.activeTab}>
                    <TabPane tabId="1">
                      <Alerts type="full" query={{ farm_ids: [state.farmId] }} />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Card>
          )}
        </>
      )}

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        title="Archive farm"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={removeFarm}>
        <div className="py-4">Are you sure you want to archive this farm? This action cannot be undone.</div>
      </AppModal>
    </>
  );
}
