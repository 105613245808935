import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import { admin } from './reducers/admin';
import animalReducer from './reducers/animal';
import { breed } from './reducers/breed';
import { breeding } from './reducers/breeding';
import { calendar } from './reducers/calendar';
import { event } from './reducers/event';
import farmReducer from './reducers/farm';
import { gateway } from './reducers/gateway';
import geofenceReducer from './reducers/geofence';
import labelReducer from './reducers/label';
import { location } from './reducers/location';
import mapReducer from './reducers/map';
import { matingEvent } from './reducers/mating-event';
import { measurement } from './reducers/measurement';
import { measurementType } from './reducers/measurement-type';
import { notification } from './reducers/notification';
import { rule } from './reducers/rule';
import siteReducer from './reducers/site';
import { subscription } from './reducers/subscription';
import { tag } from './reducers/tag';
import { task } from './reducers/task';
import treatmentReducer from './reducers/treatment';
import { user } from './reducers/user';

const rootReducer = {
  admin,
  animal: animalReducer,
  breed,
  breeding,
  calendar,
  event,
  farm: farmReducer,
  gateway,
  geofence: geofenceReducer,
  label: labelReducer,
  location,
  map: mapReducer,
  matingEvent,
  measurement,
  measurementType,
  notification,
  rule,
  site: siteReducer,
  subscription,
  tag,
  task,
  treatment: treatmentReducer,
  user
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk)
});

export default store;
