import axios from '../../axios';

const service = {
  async fetchFarms(params) {
    return axios({ method: 'get', url: '/farms', params });
  },

  async fetchFarm(id) {
    return axios({ method: 'get', url: `/farms/${id}` });
  },

  async updateFarm(id, data) {
    return axios({ method: 'put', url: `/farms/${id}`, data });
  },

  async deleteFarms(ids) {
    return axios({ method: 'delete', url: 'farms/bulk', data: { ids } });
  },

  async deleteFarm(id) {
    return axios({ method: 'delete', url: `farms/${id}` });
  }
};

export default service;
