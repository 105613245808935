import { memo } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';

function AppDropdown({ className, items, label, size = 'md' }) {
  const menuProps = {
    items: items.map((item, index) => ({
      ...item,
      key: `${index}`
    }))
  };

  return (
    <Dropdown placement="bottomRight" trigger="click" className={className} menu={menuProps}>
      <Button color="primary" variant="outlined" size={size === 'sm' ? 'middle' : 'large'}>
        <Space>
          {label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
export default memo(AppDropdown);
