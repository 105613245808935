import { memo } from 'react';

import { Radio } from 'antd';

import './styles.scss';

const CancelSaveButtons = ({ handleCancel, handleSave }) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      size="large"
      className="d-flex flex-nowrap"
      optionType="button"
      style={{
        marginLeft: '6px',
        float: 'right'
      }}>
      <Radio.Button className="btn-cancel" onClick={handleCancel}>
        Cancel
      </Radio.Button>
      <Radio.Button onClick={handleSave}>Save</Radio.Button>
    </Radio.Group>
  );
};

export default memo(CancelSaveButtons);
