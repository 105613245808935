export const KEY_MAP = {
  identifier: 'Farm ID',
  name: 'Farm Name',
  full_address: 'Farm Address',
  size: 'Farm Size (ha)',
  herd_numbers: 'Farm Herd #',
  geofence_id: 'Farm Master Geofence ID',
  description: 'Farm Description',
  circumference: 'Farm Perimeter (km)',
  address: 'Farm Address',
  street: 'Farm Address',
  house_number: 'Farm House Number',
  city: 'Farm Town',
  postal_code: 'Farm Postal Code',
  country: 'Farm Country',
  animals_count: 'Assigned Livestock',
  expected_livestock: 'Expected Tracked Livestock',
  actual_livestock: 'Actual Tracked Livestock'
};

export const NEW_FARM_KEY_MAP = {
  id: 'Farm ID',
  name: 'Farm Name',
  full_address: 'Farm Address',
  size: 'Size (ha)',
  herd_number: 'Herd #',
  geofence_id: 'Geofence ID',
  description: 'Description',
  geofences: 'Geofences',
  circumference: 'Circumference (km)',
  days_grazed: 'Days Grazed',
  farm_rules: 'Farm Rules'
};

export const ORDER = [
  'identifier',
  'name',
  'address',
  'house_number',
  'city',
  'postal_code',
  'country',
  'size',
  'circumference',
  'herd_numbers',
  'animals_count',
  'expected_livestock',
  'actual_livestock',
  'description'
];

export const ADDRESS = ['address', 'street', 'house_number', 'city', 'postal_code', 'country'];

export const blacklist_edit = [
  'size',
  'herd_nr',
  'geofence_id',
  'circumference',
  'rules',
  'identifier',
  'total_expected_livestock'
];

export const blacklist_full = ['full_address', 'user_id', 'address_id', 'created_at', 'updated_at', 'id'];

export const blacklist_create = [
  'id',
  'size',
  'address',
  'full_address',
  'geofence_id',
  'circumference',
  'days_grazed',
  'farm_rules',
  'master_geofence',
  'geofences'
];
