import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, GeoJSON, LayerGroup, LayersControl, MapContainer, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { Link } from 'react-router-dom';

import L from 'leaflet';

import MapBaseLayers from './MapBaseLayers';
import SiteFeature from './SiteFeature';
import {
  EDIT_CONTROL_POSITION,
  FULLSCREEN_CONTROL_POSITION,
  LAYERS_CONTROL_POSITION,
  ZOOM,
  ZOOM_MAX
} from './constants';
import './styles.scss';

const { Overlay } = LayersControl;

export default function MapSite(props) {
  const mapRef = useRef(null);
  const [state, setState] = useState({
    farm: props.farm,
    position: [-29.132312, 26.057016],
    editing: props.editing,
    draw: {},
    new: props.new,
    bounds: [
      [-31.0, 13.0],
      [-31.0, 33.0]
    ],
    features: props.site.features,
    color: props.color,
    leaflet_id: null,
    geofences: [],
    enableDraw: true,
    set: false,
    site: props.site
  });

  useEffect(() => {
    tick(props.farm, props.site);
  }, []);

  useEffect(() => {
    tick(props.farm, props.site);
  }, [props.farm, props.site]);

  useEffect(() => {
    if (mapRef?.current) {
      const mapContainer = mapRef?.current?.getContainer();

      props.editing ? mapContainer.classList.add('editing') : mapContainer.classList.remove('editing');
    }
  }, [props.editing]);

  useEffect(() => {
    if (!state.leaflet_id) return;

    mapRef.current.leafletElement.eachLayer(layer => {
      if (layer._leaflet_id === state.leaflet_id && typeof layer.setStyle === 'function') {
        layer.setStyle({ color: props.color });
      }
    });
  }, [props.color]);

  function calculateMapBounds(farm) {
    const group = new L.geoJson(farm);
    let bounds = group.getBounds();

    if (bounds.isValid()) {
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();

      const corner1 = L.latLng(parseFloat(northEast.lat), parseFloat(northEast.lng));
      const corner2 = L.latLng(parseFloat(southWest.lat), parseFloat(southWest.lng));

      bounds = L.latLngBounds(corner1, corner2);

      const center = bounds.getCenter();
      setState(prevState => ({ ...prevState, bounds }));

      if (mapRef?.current) {
        mapRef?.current?.setView(center, Math.min(mapRef?.current?.getBoundsZoom(bounds), ZOOM_MAX));
      }
    }
  }

  function tick(farm, site) {
    const geofences = { type: 'FeatureCollection', features: [] };
    const masterGeofences = { type: 'FeatureCollection', features: [] };
    const sites = { type: 'FeatureCollection', features: [] };

    if (!farm.meta || !farm?.meta?.features?.length) return;

    farm?.meta?.features.forEach(f => {
      const target = f.is_master ? masterGeofences.features : geofences.features;
      target.push({ ...f.geo_json, color: f.color });
    });

    farm?.meta?.sites.forEach(s => {
      if (site?.geo_json?.properties?.identifier !== s.identifier) {
        sites.features.push({ ...s.geo_json, color: s.color });
      }
    });

    setState(prevState => ({
      ...prevState,
      features_geo: geofences,
      features_master: masterGeofences,
      features_sites: sites,
      farm,
      site
    }));

    const center = site?.geo_json?.type ? site?.geo_json : masterGeofences;
    calculateMapBounds(center);
  }

  function created(e) {
    setState(prevState => ({ ...prevState, enableDraw: false }));

    const layers = L.layerGroup().addLayer(e.layer);

    setState(prevState => ({ ...prevState, leaflet_id: e.layer._leaflet_id }));

    layers.eachLayer(a => {
      if (props.onCreate) {
        const geoJson = a.toGeoJSON();
        geoJson.properties.radius = a._mRadius ? a._mRadius : a._radius;

        props.onCreate(geoJson);
      }
    });
  }

  function edited(e) {
    e.layers.eachLayer(a => {
      if (props.onEdit) {
        const geoJson = a.toGeoJSON();
        if (a._mRadius) {
          geoJson.properties.radius = a._mRadius;
        } else if (a._radius) {
          geoJson.properties.radius = a._radius;
        }
        props.onEdit(geoJson);
      }
    });
  }

  function onDelete() {
    setState(prevState => ({ ...prevState, enableDraw: true }));
  }

  const shapeOptions = {
    fillOpacity: 0.5,
    opacity: 0.5
  };

  return (
    <MapContainer className="farms map" attributionControl={false} ref={mapRef} bounds={state.bounds} zoom={ZOOM}>
      <LayersControl collapsed={!L.Browser.mobile} position={LAYERS_CONTROL_POSITION}>
        <MapBaseLayers />

        <Overlay checked name="Site">
          <LayerGroup>
            <FeatureGroup>
              <EditControl
                position={EDIT_CONTROL_POSITION}
                onEdited={edited}
                onCreated={created}
                onDeleted={onDelete}
                draw={{
                  polygon: false,
                  polyline: false,
                  point: false,
                  marker: false,
                  circle: props.editing &&
                    state.enableDraw && { shapeOptions: { ...shapeOptions, color: state.color } },
                  circlemarker: props.editing &&
                    state.enableDraw && { shapeOptions: { ...shapeOptions, color: state.color } },
                  rectangle: props.editing &&
                    state.enableDraw && { shapeOptions: { ...shapeOptions, color: state.color } }
                }}
                edit={{ edit: false, delete: true }}
              />
              {state.site.geo_json.geometry && !state.new && (
                <SiteFeature
                  color={state.color}
                  geometry={state.site.geo_json.geometry.coordinates}
                  key={JSON.stringify(state.site)}
                  new={state.new}
                  properties={state.site.geo_json.properties}
                  type={state.site.geo_json.geometry.type}
                />
              )}
            </FeatureGroup>

            {state?.features_master?.features?.map(item => (
              <GeoJSON
                key={item?.properties?.id}
                data={item}
                style={{
                  color: item.color,
                  weight: 3,
                  fillOpacity: 0.2,
                  opacity: 0.8
                }}>
                <Popup>
                  <p className="font-bold">{item.properties.name}</p>
                  {item.properties.description && <p>{item.properties.description}</p>}
                  <Link to={`/geofence/${item.properties.id}`}>More details</Link>
                </Popup>
              </GeoJSON>
            ))}

            {state?.features_geo?.features?.map(item => (
              <GeoJSON
                key={item?.properties?.id}
                data={item}
                style={{
                  color: item.color,
                  weight: 2,
                  fillOpacity: 0.2,
                  opacity: 0.8
                }}>
                <Popup>
                  <p className="font-bold">{item.properties.name}</p>
                  {item.properties.description && <p>{item.properties.description}</p>}
                  <Link to={`/geofence/${item.properties.id}`}>More details</Link>
                </Popup>
              </GeoJSON>
            ))}

            {state?.features_sites?.features
              ?.filter(item => item.geometry?.type)
              .map(item => (
                <SiteFeature
                  color={item.color}
                  type={item?.geometry?.type}
                  geometry={item?.geometry?.coordinates}
                  properties={item.properties}
                  key={item?.properties?.id}
                />
              ))}
          </LayerGroup>

          {!L.Browser.mobile && <FullscreenControl position={FULLSCREEN_CONTROL_POSITION} />}
        </Overlay>
      </LayersControl>
    </MapContainer>
  );
}
