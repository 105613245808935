export const GOOGLE_LAYER = {
  HYBRID: 'hybrid',
  TERRAIN: 'terrain'
};

export const TYPE = {
  FARM: 'farm',
  GEOFENCE: 'geofence'
};

export const DEFAULT_LINE_COLOR = '#ff0000';
export const DEFAULT_SHAPE_COLOR = '#3388ff';
export const EDIT_CONTROL_POSITION = 'topright';
export const FULLSCREEN_CONTROL_POSITION = 'topright';
export const LAYERS_CONTROL_POSITION = 'bottomright';
export const ZOOM = 10;
export const ZOOM_MAX = 20;
